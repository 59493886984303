import { Component } from '@angular/core';
import { Footer } from '../../core/shell/footer/footer';

interface BlogImage {
    id: number;
    url: string;
    title: string;
    date: string;
}

@Component({
    selector: 'page-events',
    standalone: true,
    imports: [
        Footer
    ],
    templateUrl: './events-page.component.html'
})
export class EventsPageComponent {

    blogImages: BlogImage[] = [
        {
            id: 1,
            url: '/assets/images/events/hof1.jpeg',
            title: 'Van \'t Hof',
            date: '26, September 2024'
        },
        {
            id: 2,
            url: '/assets/images/events/tempel1.jpg',
            title: 'Jaap Tempel B.V.',
            date: '14, Maart 2024'
        },
        {
            id: 3,
            url: '/assets/images/events/hof3.jpg',
            title: 'Jaap Tempel B.V.',
            date: '14, Maart 2024'
        },
        {
            id: 4,
            url: '/assets/images/events/hof4.jpg',
            title: 'Jaap Tempel B.V.',
            date: '14, Maart 2024'
        },
    ];

}

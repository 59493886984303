import { Component, computed } from '@angular/core';
import {
    CompanyOnboardingService
} from '../../../../company/onboarding/company-onboarding.service';
import { AuthService } from '../../../../auth/services/auth.service';
import { Router } from '@angular/router';
import { ScrollBehaviorService } from '../../../../core/services/scroll-behavior.service';

@Component({
    selector: 'home-we-are-planmeister',
    standalone: true,
    imports: [],
    templateUrl: './we-are-planmeister.component.html'
})
export class WeArePlanmeisterComponent {

    constructor(
        private readonly companyOnboardingService: CompanyOnboardingService,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly scrollService: ScrollBehaviorService
    ) {
    }

    public showCta = computed(() => {
        return !this.authService.isAuthenticated();
    })

    public onCtaClick() {
        this.companyOnboardingService.start();
    }

    public onProfileClick() {
        this.router
            .navigate([ '/', 'bedrijf', 'beheer', 'inventaris' ])
            .then(() => this.scrollService.scrollToTop())
    }
}

import { Component, computed } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faGlobeEurope } from '@fortawesome/pro-light-svg-icons/faGlobeEurope';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { Router, RouterLink } from '@angular/router';
import {
    faFacebookSquare,
    faInstagram,
    faLinkedinIn,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { ScrollBehaviorService } from '../../../../core/services/scroll-behavior.service';
import {
    CompanyOnboardingService
} from '../../../../company/onboarding/company-onboarding.service';
import { AuthService } from '../../../../auth/services/auth.service';

@Component({
    selector: 'home-pillars',
    standalone: true,
    imports: [
        FaIconComponent,
        RouterLink
    ],
    templateUrl: './pillars.component.html'
})
export class PillarsComponent {
    protected readonly faGlobeEurope = faGlobeEurope;
    protected readonly faHandshake = faHandshake;
    protected readonly faThumbsUp = faThumbsUp;
    protected readonly faFacebookSquare = faFacebookSquare;
    protected readonly faLinkedinIn = faLinkedinIn;
    protected readonly faYoutube = faYoutube;
    protected readonly faInstagram = faInstagram;

    constructor(
        private readonly authService: AuthService,
        private readonly companyOnboardingService: CompanyOnboardingService,
        private readonly router: Router,
        private readonly scrollService: ScrollBehaviorService
    ) {
    }

    public showCta = computed(() => {
        return !this.authService.isAuthenticated();
    })

    public onCtaClick() {
        this.companyOnboardingService.start();
    }

    public onProfileClick() {
        this.router
            .navigate([ '/', 'bedrijf', 'beheer', 'inventaris' ])
            .then(() => this.scrollService.scrollToTop())
    }
}

import { Component } from '@angular/core';
import { Footer } from '../../core/shell/footer/footer';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-not-found',
    standalone: true,
    imports: [
        Footer,
        RouterLink
    ],
    templateUrl: './not-found.component.html'
})
export class NotFoundComponent {

}

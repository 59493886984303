import { MapConfig } from "../../../types/search/map-config";
import { latLng, MapOptions, tileLayer } from "leaflet";

export function makeMapOptions(config: MapConfig): MapOptions {

    function getTileUrl(): string {
        return `https://atlas.microsoft.com/map/tile?api-version=2022-08-01&tilesetId=${ config.tileSetId }&zoom={z}&x={x}&y={y}&language=${ config.language }&subscription-key=${ config.subscriptionKey }&tileSize=${ config.tileSize }`;
    }

    return {
        zoomControl: false,
        layers: [tileLayer(getTileUrl(), {
            maxZoom: 18,
            attribution: '...',
            updateWhenIdle: true,
            updateWhenZooming: false
        })],
        zoom: config.defaultZoom,
        center: latLng(config.center),
        scrollWheelZoom: config.scrollDisabled === undefined ? true : !config.scrollDisabled
    }

}

import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { CompanyOnboardingService } from '../../company-onboarding.service';
import { UserStep } from '../../steps/user-step';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../../../auth/services/auth.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'onboarding-user',
    standalone: true,
    imports: [
        ReactiveFormsModule
    ],
    templateUrl: './user.component.html',
    styleUrl: './user.component.scss',
    providers: [ MessageService ]
})
export class UserComponent implements OnInit, OnDestroy {
    protected readonly destroy$ = new Subject<void>();

    prevCallback = input<() => void>();
    nextCallback = input<() => void>();

    constructor(
        private onboardingService: CompanyOnboardingService,
        public readonly userStep: UserStep,
        public readonly authService: AuthService,
        private readonly messageService: MessageService
    ) {

    }

    ngOnInit() {
        const currentOnboarding = this.onboardingService.onboarding();

        if (currentOnboarding) {
            // this.userStep.syncFormWithOnboarding(currentOnboarding);
        }
    }

    onSubmit() {
        if (this.userStep.form.valid) {
            const onboardingId = this.onboardingService.getCurrentOnboardingId();

            if (onboardingId) {
                this.userStep.set(onboardingId)
                    .pipe(
                        takeUntil(this.destroy$),
                    )
                    .subscribe({
                        next: (onboarding) => {
                            this.authService.signIn(
                                this.userStep.form.value.email,
                                this.userStep.form.value.password
                            ).subscribe({
                                next: () => {
                                    this.onboardingService.complete();
                                }
                            })

                            console.log('User step completed');
                        },
                        error: (error) => console.error('Error setting contact information:', error)
                    });
            } else {
                console.error('No onboarding ID found');
            }
        }
    }

    protected readonly UserStep = UserStep;

    onPrevious() {
        this.prevCallback();
    }

    onCancel() {
        this.onboardingService.cancel();
    }

    onResumeLater() {
        this.onboardingService.resumeLater();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { CompanyOnboardingService } from '../../company-onboarding.service';
import { BasicInformationStep } from '../../steps/basic-information-step';
import { ReactiveFormsModule } from '@angular/forms';
import { StepWrapperComponent } from '../step-wrapper/step-wrapper.component';
import { Subject, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'onboarding-basic-information',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        StepWrapperComponent
    ],
    templateUrl: './basic-information.component.html',
    styleUrl: './basic-information.component.scss'
})
export class BasicInformationComponent implements OnInit, OnDestroy {
    protected readonly destroy$ = new Subject<void>();

    prevCallback = input<() => void>();
    nextCallback = input<() => void>();

    constructor(
        private onboardingService: CompanyOnboardingService,
        public basicInfoStep: BasicInformationStep
    ) {
    }

    ngOnInit() {
        const currentOnboarding = this.onboardingService.onboarding();

        if (currentOnboarding) {
            this.basicInfoStep.syncFormWithOnboarding(currentOnboarding);
        }
    }

    onSubmit() {
        if (this.basicInfoStep.form.valid) {
            const onboardingId = this.onboardingService.getCurrentOnboardingId();

            if (onboardingId) {
                this.basicInfoStep.set(onboardingId)
                    .pipe(
                        takeUntil(this.destroy$),
                        switchMap(() => this.basicInfoStep.complete(onboardingId))
                    )
                    .subscribe({
                        next: (onboarding) => {
                            this.onboardingService.updateOnboarding(onboarding);
                        },
                        error: (error) => console.error('Error setting basic information:', error)
                    });
            } else {
                console.error('No onboarding ID found');
            }
        }
    }

    protected readonly BasicInformationStep = BasicInformationStep;

    onCancel() {
        this.onboardingService.cancel();
    }

    onResumeLater() {
        this.onboardingService.resumeLater();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

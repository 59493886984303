import { Injectable } from '@angular/core';
import { OnboardingStep } from './onboarding-step';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyOnboardingService } from '../company-onboarding.service';
import {
    CompanyOnboardingCompleteLocationGQL,
    CompanyOnboardingFragment,
    CompanyOnboardingSetLocationGQL,
    CompanyOnboardingSetLocationInput,
    LocationInput,
    LocationStepFragment
} from '../../../../../graphql/generated';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// From the onboarding
// export type LocationStepFragment = { __typename: 'LocationStep', isComplete: boolean, name: string, isValid: boolean, location?: { __typename?: 'Location', type: string, geometry: { __typename?: 'GeoJSONPointType', coordinates?: any | null }, properties: { __typename?: 'LocationProperties', formattedAddress: string, address: { __typename?: 'StructuredAddress', street: string, houseNumber: string, postcode: string, city: string, country: string } } } | null };

// The input
//export type CompanyOnboardingSetLocationInput = {
//   address: StructuredAddressInput;
//   companyOnboardingId: Scalars['UUID']['input'];
//   formattedAddress: Scalars['String']['input'];
//   latitude: Scalars['Float']['input'];
//   longitude: Scalars['Float']['input'];
// };

// From the form control output
//
// export type LocationInput = {
//     geometry: GeoJsonPointInput;
//     properties: LocationPropertiesInput;
// };

@Injectable({ providedIn: 'root' })
export class LocationStep implements OnboardingStep {
    stepIndex = 2;
    form!: FormGroup;
    isValid = new BehaviorSubject<boolean>(false);

    private locationState = new BehaviorSubject<LocationStepFragment | null>(null);

    getLocationState(): Observable<LocationStepFragment | null> {
        return this.locationState.asObservable();
    }

    updateLocationState(location: LocationStepFragment): void {
        this.locationState.next(location);
    }

    constructor(
        private fb: FormBuilder,
        private onboardingService: CompanyOnboardingService,
        private setLocation: CompanyOnboardingSetLocationGQL,
        private completeLocation: CompanyOnboardingCompleteLocationGQL
    ) {
        this.initForm();
    }

    private mapLocationInputToSetLocationInput(
        locationInput: LocationInput,
        companyOnboardingId: string
    ): CompanyOnboardingSetLocationInput {
        const { properties, geometry } = locationInput;
        return {
            companyOnboardingId,
            address: properties.address,
            formattedAddress: properties.formattedAddress,
            latitude: geometry.coordinates[1],
            longitude: geometry.coordinates[0]
        };
    }

    initForm(): void {
        this.form = this.fb.group({
            locationInput: [ null, Validators.required ],
        });

        this.form.get('locationInput')?.valueChanges.subscribe(value => {
            this.isValid.next(!!value);
        });
    }

    syncFormWithOnboarding(onboarding: CompanyOnboardingFragment): void {
        const step = onboarding?.steps?.[this.stepIndex] as LocationStepFragment;
        if (step?.location) {

            //properties without the type

            const properties = {
                formattedAddress: step.location.properties.formattedAddress,
                address: {
                    street: step.location.properties.address.street,
                    houseNumber: step.location.properties.address.houseNumber,
                    postcode: step.location.properties.address.postcode,
                    city: step.location.properties.address.city,
                    country: step.location.properties.address.country
                }
            }

            const locationInput: LocationInput = {
                geometry: step.location.geometry,
                properties: properties
            };

            this.form.patchValue({
                locationInput: locationInput
            }, { emitEvent: false });

            this.updateLocationState(step);
        }
    }

    set(onboardingId: string): Observable<CompanyOnboardingFragment> {
        const locationInput = this.form.get('locationInput')?.value;
        if (!locationInput) {
            return throwError(() => new Error('Location input is missing'));
        }

        const input = this.mapLocationInputToSetLocationInput(locationInput, onboardingId);

        return this.setLocation.mutate({ input }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingSetLocation;
                if (!onboarding) {
                    throw new Error('Failed to set location information');
                }
                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
                const locationStep = onboarding.steps?.[this.stepIndex] as LocationStepFragment;
                this.updateLocationState(locationStep);
                this.isValid.next(locationStep?.isValid || false);
            })
        );
    }

    complete(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (!this.isValid.value) {
            return throwError(() => new Error('Location step is not valid'));
        }

        return this.completeLocation.mutate({ companyOnboardingId: onboardingId }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingCompleteLocation;
                if (!onboarding) {
                    console.error('Failed to complete location step');
                    throw new Error('Failed to complete location step');
                }
                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
                const locationStep = onboarding.steps?.[this.stepIndex] as LocationStepFragment;
                this.updateLocationState(locationStep);
            })
        );
    }
}

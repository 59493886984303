<div class="fixed top-0 z-50 w-full">
    @if (stack.length > 0) {
        <div
            [@fadeInOut]
            (click)="closeModal()"
            class="h-screen max-h-screen backdrop-brightness-75"
        >
        </div>
        <div class="absolute top-0 pointer-events-none h-full w-full overflow-y-auto flex">
            @for (modal of stack; track modal; let i = $index) {
                <div
                    [@modalEnterExit]
                    class="py-24 w-full mx-auto pointer-events-auto h-full"
                    [class]="{
                        'hidden': i !== stack.length - 1,
                        'max-w-sm': modal.options?.size === 'sm',
                        'max-w-md': modal.options?.size === 'md',
                        'max-w-lg': modal.options?.size === 'lg',
                        'max-w-xl': modal.options?.size === 'xl',
                        'max-w-2xl': modal.options?.size === '2xl',
                        'max-w-3xl': modal.options?.size === '3xl',
                        'max-w-4xl': modal.options?.size === '4xl',
                        'max-w-5xl': modal.options?.size === '5xl',
                        'max-w-6xl': modal.options?.size === '6xl',
                        'max-w-7xl': modal.options?.size === '7xl',
                    }"
                >
                    <app-dynamic-component
                        (initialized)="initializeModal(modal, $event)"
                        [component]="modal.component"
                        [inputs]="modal.inputs"
                        [outputs]="modal.outputs"
                    />
                </div>
            }
        </div>
    }
</div>

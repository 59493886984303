import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { CompanyOnboardingService } from '../../company-onboarding.service';
import { MediaStep } from '../../steps/media-step';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '../../../../core/services/modal.service';
import {
    UploadHeaderModalComponent
} from '../../../components/upload-header-modal/upload-header-modal.component';
import { NgIcon } from '@ng-icons/core';
import {
    UploadOnboardingImageModalComponent
} from './components/upload-onboarding-image-modal/upload-onboarding-image-modal.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'onboarding-media',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIcon,
        NgClass
    ],
  templateUrl: './media.component.html',
  styleUrl: './media.component.scss'
})
export class MediaComponent implements OnInit, OnDestroy {
    protected readonly destroy$ = new Subject<void>();

    prevCallback = input<() => void>();
    nextCallback = input<() => void>();

    constructor(
        private onboardingService: CompanyOnboardingService,
        public mediaStep: MediaStep,
        private readonly modalService: ModalService
    ) {
    }

    ngOnInit() {
        const currentOnboarding = this.onboardingService.onboarding();

        if (currentOnboarding) {
            this.mediaStep.syncFormWithOnboarding(currentOnboarding);
        }
    }

    onOpenLogoUploadModal() {
        this.modalService.open({
            component: UploadOnboardingImageModalComponent,
            inputs: {
                companyOnboardingId: this.onboardingService.currentOnboardingId(),
                imageType: 'logo'
            },
            outputs: {
                uploadedImageId: (imageId: string) => {
                    console.log('Uploaded image ID:', imageId);
                    this.mediaStep.form.patchValue({ logoId: imageId });
                }
            }
        })
    }

    onOpenHeaderUploadModal() {
        this.modalService.open({
            component: UploadOnboardingImageModalComponent,
            inputs: {
                companyOnboardingId: this.onboardingService.currentOnboardingId(),
                imageType: 'header'
            },
            outputs: {
                uploadedImageId: (imageId: string) => {
                    console.log('Uploaded image ID:', imageId);
                    this.mediaStep.form.patchValue({ headerId: imageId });
                }
            }
        })
    }

    onSubmit() {
        if (this.mediaStep.form.valid) {
            const onboardingId = this.onboardingService.getCurrentOnboardingId();

            if (onboardingId) {
                this.mediaStep.set(onboardingId)
                    .pipe(
                        takeUntil(this.destroy$),
                        switchMap(() => this.mediaStep.complete(onboardingId))
                    )
                    .subscribe({
                        next: (onboarding) => {
                            this.onboardingService.updateOnboarding(onboarding);
                        },
                        error: (error) => console.error('Error setting media:', error)
                    });
            } else {
                console.error('No onboarding ID found');
            }
        }
    }

    protected readonly MediaStep = MediaStep;

    onPrevious() {
        this.onboardingService.previousStep();
    }

    onCancel() {
        this.onboardingService.cancel();
    }

    onResumeLater() {
        this.onboardingService.resumeLater();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

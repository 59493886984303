<div class="relative isolate overflow-hidden bg-white px-6 pt-16 shadow-2xl sm:rounded-xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
    <!--    <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">-->
    <!--        <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7"/>-->
    <!--        <defs>-->
    <!--            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">-->
    <!--                <stop stop-color="#FF0000"/>-->
    <!--                <stop offset="1" stop-color="#FF0000"/>-->
    <!--            </radialGradient>-->
    <!--        </defs>-->
    <!--    </svg>-->
    <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:pb-16 lg:pt-24 lg:text-left" autoAnimate>
        <h2 class="text-3xl font-bold tracking-tight text-black sm:text-4xl">Word nu Planmeister en haal meer uit je bedrijf.</h2>
        <p class="mt-6 text-md leading-6 text-gray-500">Weet wat, waar en wanneer door wie wordt aangeboden met behulp van de handige kaartweergave. Start nu met het online aanbieden van jouw vakmensen, machines, materialen en grond- en bouwstoffen.</p>
<!--        <div class="mt-6 flex items-center justify-center gap-x-6 lg:justify-start w-full">-->
<!--            <dt class="text-sm font-medium leading-6 text-gray-900">-->
<!--                Neem direct contact op via-->
<!--                <a href="tel:+31850044938" class="text-md leading-6 text-red-500 hover:text-red-700 transition-colors duration-300 underline hover:underline">-->
<!--                    +31 85 004 49 38-->
<!--                </a>-->
<!--            </dt>-->
<!--        </div>-->


        <div class="mt-6 items-center justify-center gap-x-6 lg:justify-start w-full hidden md:flex">
            <dt class="text-sm font-medium leading-6 text-gray-900">
                Vul je KvK-nummer in
            </dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700">
                <p-iconfield iconPosition="right" class="w-full">
                    @if (loading()) {
                        <p-inputicon class="pi pi-spinner pi-spin z-10"/>
                    } @else if (prefetchResult()?.isValid) {
                        <p-inputicon class="pi pi-check-circle text-green-500 z-10"/>
                    } @else if (prefetchResult()?.isValid === false) {
                        <p-inputicon class="pi pi-times-circle text-red-500 z-10"/>
                    } @else {
                        <p-inputicon class="pi pi-search z-10"/>
                    }
                    <p-inputmask
                        mask="99999999"
                        type="text"
                        [(ngModel)]="chamberOfCommerceNumber"
                        placeholder="KvK-nummer"
                        pInputText
                        styleClass="rounded-sm border-0 py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                    />
                </p-iconfield>
            </dd>
        </div>
        @if (prefetchResult()?.needsDomain && prefetchResult()?.isValid) {
            <div class="mt-4 items-center justify-center gap-x-6 lg:justify-start hidden md:flex">
                <p class="text-sm text-black opacity-30">We hebben je KvK-nummer gevonden. Vul je website in om meer gegevens op te halen.</p>
            </div>
            <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                    Website
                </dt>
                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <input
                        [(ngModel)]="domain"
                        class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                    >
                </dd>
            </div>
        }
        <div class="mt-4 items-center justify-center gap-x-6 lg:justify-start hidden md:flex">
            <p class="text-sm text-black opacity-30">We gebruiken je KvK-nummer om je bedrijfsgegevens alvast op te halen, zodat het aanmeldingsproces sneller gaat.</p>
        </div>
        @if (prefetchResult()?.isValid) {
            <div class="mt-4 flex items-center justify-center gap-x-6 lg:justify-start p-4 bg-green-50 rounded-md">
                <p class="text-sm text-black">
                    Welkom bij Planmeister, {{ prefetchResult()?.companyInfo?.name }}
                    @if (prefetchResult()?.companyInfo?.logos?.[0]) {
                        <img [src]="prefetchResult()?.companyInfo?.logos?.[0]" alt="Planmeister" class="h-5 ml-1 w-auto inline-block -mt-1">
                    }
                </p>
            </div>
        } @else if (prefetchResult()?.isValid === false) {
            <div class="mt-4 flex items-center justify-center gap-x-6 lg:justify-start p-4 bg-red-50 rounded-md">
                <p class="text-sm text-black">
                    We konden geen bedrijfsgegevens vinden voor dit KvK-nummer.
                </p>
            </div>
        }
        <div class="mt-10 items-center justify-center lg:justify-start hidden md:flex">
            <button
                (click)="onNextButtonClick()"
                type="button"
                class="inline-flex w-full justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-normal text-white shadow-sm ring-1 ring-inset ring-red-600 hover:bg-red-500 sm:w-auto disabled:opacity-50"
            >
                Ga verder {{ prefetchResult()?.isValid ? 'met ' + prefetchResult()?.companyInfo?.name : 'zonder KvK-nummer' }}
            </button>
            <button
                (click)="onCancelButtonClick()"
                type="button"
                class="inline-flex w-full justify-center rounded-sm bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:ml-3  hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:opacity-50"
            >
                Sluiten
            </button>
        </div>
        <div class="flex md:hidden">
            <p class="text-sm text-gray-500 mt-4">Maak een account aan op je desktop of laptop, of neem contact op met Planmeister via <a href="tel:+31850044938" class="text-red-500 hover:text-red-700 transition-colors duration-300 underline hover:underline">+31 85 004 49 38</a>.</p>
        </div>
    </div>
    <div class="relative mt-4 h-80 lg:mt-16">
        <img class="absolute left-0 top-0 w-[65rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10" src="/assets/images/planmeister-demo-search.png" alt="App screenshot" width="3836" height="2250">
    </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { OnboardingStep } from './onboarding-step';
import {
    CompanyOnboardingCompleteContactInformationGQL,
    CompanyOnboardingFragment,
    CompanyOnboardingSetContactInformationGQL,
    ContactInformationStepFragment
} from '../../../../../graphql/generated';
import { map, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyOnboardingService } from '../company-onboarding.service';
import { phoneNumberValidator } from '../../../core/validators/phone-number-validator';
import { PhoneNumberFormat } from 'google-libphonenumber';

@Injectable({ providedIn: 'root' })
export class ContactInformationStep implements OnboardingStep {
    stepIndex = 1;
    form!: FormGroup;
    isValid = new BehaviorSubject<boolean>(false);

    constructor(
        private fb: FormBuilder,
        private onboardingService: CompanyOnboardingService,
        private setContactInformation: CompanyOnboardingSetContactInformationGQL,
        private completeContactInformation: CompanyOnboardingCompleteContactInformationGQL
    ) {
        this.initForm();
    }

    initForm(): void {
        this.form = this.fb.group({
            email: [ '', [ Validators.required, Validators.email ] ],
            phoneNumber: [ '', [ Validators.required, phoneNumberValidator({
                regionCode: 'NL',
                formatOnValid: true,
                format: PhoneNumberFormat.E164
            }) ] ],
            plannerPhoneNumber: [ '', [ phoneNumberValidator({
                regionCode: 'NL',
                formatOnValid: true,
                format: PhoneNumberFormat.E164
            }) ] ]
        });

        this.form.statusChanges.subscribe(status => {
            this.isValid.next(status === 'VALID');
        });
    }

    syncFormWithOnboarding(onboarding: CompanyOnboardingFragment): void {
        const step = onboarding?.steps?.[this.stepIndex] as ContactInformationStepFragment;

        if (onboarding) {
            this.form.patchValue({
                email: step?.email || '',
                phoneNumber: step?.phoneNumber || '',
                plannerPhone: step?.plannerPhoneNumber || ''
            }, { emitEvent: false });
        }
    }

    set(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (!this.isValid.value) {
            return throwError(() => new Error('Contact information step is not valid'));
        }

        return this.setContactInformation.mutate({
            input: {
                companyOnboardingId: onboardingId,
                email: this.form.value.email,
                phoneNumber: this.form.value.phoneNumber,
                plannerPhone: this.form.value.plannerPhoneNumber
            }
        }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingSetContactInformation;
                if (!onboarding) {
                    throw new Error('Failed to set contact information');
                }
                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
            }),
            tap(onboarding => {
                this.isValid.next(onboarding.steps?.[this.stepIndex]?.isValid || false);
            })
        );
    }

    complete(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (!this.isValid.value) {
            return throwError(() => new Error('Contact information step is not valid'));
        } else {
            console.log('Contact information step is valid');
        }

        return this.completeContactInformation.mutate({ companyOnboardingId: onboardingId }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingCompleteContactInformation;
                if (!onboarding) {
                    throw new Error('Failed to complete contact information step');
                } else {
                    console.log('Completed contact information step');
                }
                console.log('Completed contact information step onboarding:', onboarding)

                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
            })
        );
    }
}

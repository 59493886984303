<div class="relative isolate overflow-hidden pt-14">
    <div class="mx-auto max-w-screen-2xl px-8 lg:px-0 py-32 lg:pt-32 lg:pb-16 relative z-10 grid grid-cols-6 lg:grid-cols-12">
        <div class="col-span-6">
            <h1 class="text-4xl font-bold tracking-tight text-black sm:text-6xl font-brand">Informeel netwerken voor iedereen in de GWW-sector!</h1>
            <p class="mt-12 text-xl leading-8 text-black">De Planmeister netwerkavonden bieden een unieke kans voor iedereen die werkzaam is in de GWW-sector om ervaringen te delen, nieuwe contacten te leggen en inspiratie op te doen. Wat in 2021 begon als een intieme bijeenkomst met 30 gasten, zijn de avonden inmiddels uitgegroeid tot een evenement waar meer dan 300 GWW'ers bij elkaar komen per editie.</p>
            <a href="https://forms.office.com/pages/responsepage.aspx?id=bvkMv7IXuU2AmYWEDmPKFqCUGLvsnptCiiLNd8s_V7ZUMjBXN05JVzhFREVJREQ1TlZJMUc3VUE5Vy4u&origin=QRCode&route=shorturl" target="_blank" class="mt-12 inline-flex items-center rounded-md border border-transparent bg-red-600 px-5 py-3 text-base font-medium text-white shadow hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Meld je aan voor de volgende netwerkavond</a>

        </div>
        <div class="col-span-6 py-20 lg:py-0 px-24">
            <img src="assets/images/events/flyer-20241128.png" alt="Flyer van de netwerkavond" class="w-full">
        </div>
    </div>
    <div class="mx-auto max-w-screen-2xl px-6 lg:px-0 pb-24">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div class="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div>
                    <p class="text-xl">Met ongeveer 30-40 exposanten per avond, krijg je de kans om direct in contact te komen met leveranciers en bedrijven die innovatieve producten en diensten presenteren die relevant zijn voor de toekomst van de sector.</p>
                    <p class="text-xl mt-8">In een ontspannen en informele sfeer worden niet alleen de nieuwste ontwikkelingen en trends besproken, maar krijg je ook de gelegenheid om waardevolle connecties te leggen en samenwerkingen te verkennen. Of je nu een ervaren professional bent of nieuw in de branche, de netwerkavonden bieden iedereen de kans om het eigen netwerk uit te breiden en inzichten te delen.</p>
                </div>
                <div>
                    <p class="text-xl"><b>Waar en Wanneer?</b> De volgende editie vindt plaats bij <b>Van der Ziel</b>, Oostweg 13, 8256 SB Biddinghuizen op <b>donderdag 28 november 2024 om 18.30</b>. De avond wordt gekenmerkt door inspirerende sprekers en een netwerkborrel waar je op een laagdrempelige manier met collega’s uit de branche in gesprek kunt gaan.</p>
                    <p class="text-xl mt-8"><b>Zorg dat je erbij bent!</b> Meld je eenvoudig aan via het inschrijfformulier op deze pagina. Deelname aan de netwerkavond is gratis, maar het aantal plaatsen is beperkt. Wacht dus niet te lang en verzeker jezelf van een plek op deze waardevolle avond vol kennis, innovatie en netwerkmogelijkheden.</p>
                </div>
            </div>
        </div>
        <div class="mx-auto mt-24 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            @for (image of blogImages; track image.id) {
                <div class="aspect-[3/2] overflow-hidden rounded-lg relative group">
                    <img
                        [src]="image.url"
                        [alt]="image.title"
                        class="h-full w-full object-cover transition-opacity"
                    />
                    <div class="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black/60 to-transparent p-4 opacity-0 group-hover:opacity-100 transition-opacity">
                        <h3 class="text-sm font-semibold text-white">{{ image.title }}</h3>
                        <p class="text-xs text-gray-300">{{ image.date }}</p>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
<shell-footer/>

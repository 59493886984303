import { DivIcon, Point, markerClusterGroup, MarkerClusterGroup } from "leaflet";

export function makeMarkerClusterGroup(): MarkerClusterGroup {
    return markerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        polygonOptions: {
            color: '#E70303',
            weight: 2,
            opacity: 0.5
        },
        maxClusterRadius: 25,
        iconCreateFunction: (cluster) => {
            const childCount = cluster.getChildCount();
            let c = ' marker-cluster-large';

            return new DivIcon({
                html: '<div><span>' + childCount + '</span></div>',
                className: 'marker-cluster marker-cluster-primary',
                iconSize: new Point(28, 28)
            });
        }
    });
}

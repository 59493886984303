<div class="rounded-lg shadow-xl pointer-events-auto bg-white">
    <div
        class="bg-white rounded-lg overflow-hidden divide-y divide-gray-100"
        autoAnimate
    >
        @for (item of items(); track item.segment) {
            @if (!item.disabled) {
                <div
                    (click)="navigateToSegment(item.segment)"
                    [appTooltip]="item.tooltip"
                    class="p-5 cursor-pointer transition-all duration-200 ease-in-out flex justify-center items-center bg-white w-[70px] relative"
                    [ngClass]="[segment() === item.segment ? 'shadow-inner bg-slate-100' : 'hover:bg-slate-50 hover:shadow-inner']"
                >
                    <fa-icon
                        [icon]="item.icon"
                        [ngClass]="segment() === item.segment ? 'text-black' : 'text-gray-300'"
                        size="xl"
                    />
                    @if (segment() === item.segment) {
                        <div class="absolute top-2 right-2 w-2 h-2 bg-red-600 rounded-full"></div>
                    }
                </div>
            }
        }
    </div>
</div>

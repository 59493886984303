import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { OnboardingStep } from './onboarding-step';
import {
    BasicInformationStepFragment,
    CompanyOnboardingCompleteBasicInformationGQL,
    CompanyOnboardingFragment,
    CompanyOnboardingSetBasicInformationGQL
} from '../../../../../graphql/generated';
import { map, tap } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyOnboardingService } from '../company-onboarding.service';

@Injectable({ providedIn: 'root' })
export class BasicInformationStep implements OnboardingStep {
    stepIndex = 0;
    form!: FormGroup;
    isValid = new BehaviorSubject<boolean>(false);

    constructor(
        private fb: FormBuilder,
        private onboardingService: CompanyOnboardingService,
        private setBasicInformation: CompanyOnboardingSetBasicInformationGQL,
        private completeBasicInformation: CompanyOnboardingCompleteBasicInformationGQL
    ) {
        this.initForm();
    }

    initForm(): void {
        this.form = this.fb.group({
            companyName: [ '', [ Validators.required, Validators.minLength(2) ] ],
            description: [ '', [Validators.required, Validators.maxLength(500)] ],
            slug: [ '', [ Validators.required, Validators.pattern(/^[a-z0-9-]+$/) ] ],
            website: [ '', [ this.websiteValidator ] ]
        });

        this.form.statusChanges.subscribe(status => {
            this.isValid.next(status === 'VALID');
        });

        this.form.get('companyName')?.valueChanges.subscribe(value => {
            // If the company name changes, update the slug, but only if the slug is not already set
            if (!this.form.get('slug')?.value) {
                const slug = this.generateSlug(value);
                this.form.get('slug')?.setValue(slug, { emitEvent: false });
            }
        });
    }

    private generateSlug(companyName: string): string {
        return companyName
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/^-+|-+$/g, '');
    }

    private websiteValidator(control: AbstractControl): { [key: string]: any } | null {
        const value = control.value;
        if (!value) {
            return null; // Allow empty value
        }
        const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        return urlPattern.test(value) ? null : { invalidWebsite: true };
    }

    syncFormWithOnboarding(onboarding: CompanyOnboardingFragment): void {
        const step = onboarding?.steps?.[this.stepIndex] as BasicInformationStepFragment;

        if (onboarding) {
            this.form.patchValue({
                companyName: step.companyName,
                description: step.description,
                slug: step.slug,
                website: this.stripProtocol(step.website ?? '')
            }, { emitEvent: false });
        }
    }

    private stripProtocol(url: string): string {
        return url.replace(/^https?:\/\//, '');
    }

    private ensureProtocol(url: string): string {
        if (!url) {
            return '';
        }
        if (!url.match(/^https?:\/\//)) {
            return `https://${url}`;
        }
        return url;
    }

    set(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (this.form.invalid) {
            return throwError(() => new Error('Form is invalid'));
        }

        const formValue = { ...this.form.value };
        formValue.website = this.ensureProtocol(formValue.website);

        return this.setBasicInformation.mutate({
            input: {
                companyOnboardingId: onboardingId,
                ...formValue
            }
        }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingSetBasicInformation;
                if (!onboarding) {
                    throw new Error('Failed to set basic information');
                }
                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
            }),
            tap(onboarding => {
                this.isValid.next(onboarding.steps?.[this.stepIndex]?.isValid || false);
            })
        );
    }

    complete(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (!this.isValid.value) {
            return throwError(() => new Error('Basic information step is not valid'));
        } else {
            console.log('Basic information step is valid');
        }

        return this.completeBasicInformation.mutate({ companyOnboardingId: onboardingId }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingCompleteBasicInformation;
                if (!onboarding) {
                    throw new Error('Failed to complete basic information step');
                } else {
                    console.log('Completed basic information step');
                }
                console.log('Completed basic information step onboarding:', onboarding)

                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
            })
        );
    }
}

import { AfterViewInit, Component, input, OnInit, output, signal } from '@angular/core';
import { NgClass } from "@angular/common";
import { ColorMode } from "../../../../types/color-mode";
import { NavItem } from "../../../../types/nav-item";
import { Router, RouterLink } from "@angular/router";
import { NgIcon } from "@ng-icons/core";
import { OnClickOutsideDirective } from "@knalgeel/pandora";
import { ClickOutsideDirective } from "../../../directives/click-outside.directive";
import { Subject } from "rxjs";

@Component({
    selector: 'app-nav-menu',
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        NgIcon,
        OnClickOutsideDirective,
        ClickOutsideDirective
    ],
    templateUrl: './nav-menu.component.html',
    styleUrl: './nav-menu.component.scss'
})
export class NavMenuComponent implements AfterViewInit {

    private readonly $destroyed = new Subject<void>();

    public readonly close = output<void>();

    public readonly colorMode = input.required<ColorMode>();

    protected readonly initialized = signal<boolean>(false);

    public readonly navItems: NavItem[] = [
        {
            title: 'Mijn aanbod',
            icon: 'heroMapPin',
            route: ['bedrijf', 'beheer'],
            description: 'Bekijk en beheer alle aanbod'
        },
        {
            title: 'Mijn aanvragen',
            icon: 'heroArrowsUpDown',
            route: ['bedrijf', 'beheer', 'vraag'],
            description: 'Bekijk en beheer aanvragen'
        },
        // {
        //     title: 'Inzichten',
        //     icon: 'heroArrowTrendingUp',
        //     route: ['bedrijf', 'beheer', 'inzichten'],
        //     description: 'Bekijk statistieken en inzichten'
        // },
        {
            title: 'Inventaris',
            icon: 'heroBuildingOffice',
            route: ['bedrijf', 'beheer', 'inventaris'],
            description: 'Beheer de inventaris van je bedrijf'
        },
        {
            title: 'Instellingen',
            icon: 'heroCog',
            route: ['bedrijf', 'beheer', 'instellingen'],
            description: 'Pas de instellingen van je bedrijf aan'
        },
    ]

    constructor(
        private readonly router: Router
    ) {}

    // ----------[ Lifecycle ]----------

    public ngAfterViewInit() {
        this.initialized.set(true);
    }

    // ----------[ Event Handlers ]----------

    public onClickOutside() {
        this.close.emit();
    }

    public onClick() {
        this.close.emit();
    }
}

import { Component } from '@angular/core';
import { Footer } from '../../core/shell/footer/footer';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'page-contact',
    standalone: true,
    imports: [
        Footer,
        FaIconComponent
    ],
    templateUrl: './contact-page.component.html'
})
export class ContactPageComponent {

    protected readonly faEnvelope = faEnvelope;
    protected readonly faPhone = faPhone;
    protected readonly faWhatsapp = faWhatsapp;
}

<div class="bg-white">
    <div class="mx-auto max-w-screen-2xl py-24 px-6 2xl:px-0">
        <div class="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
            <div class="mx-auto w-full max-w-xl lg:mx-0">
                <h2 class="xl:text-5xl lg:text-4xl text-3xl font-bold tracking-tight font-brand text-black">Vertrouwd door bedrijven bij jou in de buurt</h2>
                <p class="mt-6 text-xl leading-8 text-gray-600">Veel bedrijven hebben al kennis gemaakt met het Planmeister-netwerk. Kijk snel wie er al lid is bij jou in de buurt.</p>
                <div class="mt-8 flex items-center gap-x-6">
                    <a [routerLink]="'/bedrijven'" class="text-md font-semibold text-black hover:text-gray-400">Bekijk alle bedrijven op Planmeister <span aria-hidden="true">&rarr;</span></a>
                </div>
            </div>
            <div class="mx-auto grid w-full max-w-xl grid-cols-2 items-end gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
                @for (hit of companySearchHits(); track hit.company.id) {
                    @if (hit.company.logo?.image) {
                        <img class="max-h-12 w-full object-contain object-center"
                             [image]="hit.company.logo?.image"
                             [alt]="hit.company.name || 'Company logo'"
                             [width]="210"
                             [height]="96"
                        >
                    }
                }
            </div>
        </div>
    </div>
</div>



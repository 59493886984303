import { Component, computed, model, OnDestroy, signal } from '@angular/core';
import {
    PrefetchCompanyInfoGQL,
    PrefetchCompanyInfoResult
} from '../../../../../../graphql/generated';
import { InputMaskModule } from 'primeng/inputmask';
import { FormsModule } from '@angular/forms';
import { ModalService } from '../../../../core/services/modal.service';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { Subject, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InputTextModule } from 'primeng/inputtext';
import { CompanyOnboardingService } from '../../company-onboarding.service';

@Component({
    selector: 'onboarding-company-intro',
    standalone: true,
    imports: [
        InputMaskModule,
        FormsModule,
        AutoAnimateDirective,
        IconFieldModule,
        InputIconModule,
        InputTextModule
    ],
    templateUrl: './company-onboarding-intro.component.html',
    styleUrl: './company-onboarding-intro.component.scss'
})
export class CompanyOnboardingIntroComponent implements OnDestroy {
    protected onDestroy = new Subject<void>();

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    chamberOfCommerceNumber = model<string>();
    domain = model<string>();
    loading = model<boolean>(false);

    prefetchResult = signal<PrefetchCompanyInfoResult | null>(null);

    companyResult = computed(() => {
        const companyInfo = this.prefetchResult()?.companyInfo;

        if (companyInfo) {
            return {
                isValid: this.prefetchResult()?.isValid,
                needsDomain: this.prefetchResult()?.needsDomain,
                name: companyInfo.name,
                logoUrl: companyInfo.logos.length > 0 ? companyInfo.logos[0] : null
            };
        }

        return null;
    });

    constructor(
        private readonly onboardingService: CompanyOnboardingService,
        private readonly prefetchCompanyInfoGQL: PrefetchCompanyInfoGQL,
        private readonly modalService: ModalService
    ) {
        this.chamberOfCommerceNumber.subscribe(() => {
            if (this.chamberOfCommerceNumber()?.length === 8
                && !isNaN(Number(this.chamberOfCommerceNumber()))
            ) {
                this.prefetchCompanyData();
            }
        });
    }

    prefetchCompanyData() {
        this.prefetchCompanyInfoGQL
            .fetch({
                chamberOfCommerceNumber: this.chamberOfCommerceNumber()!
            })
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => this.loading.set(true))
            )
            .subscribe(result => {
                this.prefetchResult.set(
                    result.data.prefetchCompanyInfo as unknown as PrefetchCompanyInfoResult
                );
                this.loading.set(false);
            });
    }

    onNextButtonClick() {
        this.loading.set(true);

        this.onboardingService.initiateOnboardingProcess(
            this.chamberOfCommerceNumber(),
            this.domain()
        ).subscribe({
            next: () => {
                this.loading.set(false);
            },
            error: (error) => console.error('Error initiating onboarding:', error)
        });
    }

    onCancelButtonClick() {
        this.modalService.close();
    }
}

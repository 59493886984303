<div
    class="w-screen max-w-md flex-auto overflow-hidden rounded-lg text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
    (click)="onClick()"
    (clickOutside)="onClickOutside()"
    [ngClass]="colorMode() === 'light' ? 'bg-white text-gray-900' : 'bg-black text-white'"
>
    <div class="p-2">
        @for (item of navItems; track item) {
            <a
                [routerLink]="item.route"
                class="group relative flex gap-x-4 rounded-lg p-2 items-center"
                [ngClass]="colorMode() === 'light' ? 'hover:bg-gray-50' : 'hover:bg-gray-800'"
            >
                <div
                    class="flex h-11 w-11 flex-none items-center justify-center rounded-md text-white"
                    [ngClass]="colorMode() === 'light' ? 'bg-red-500 group-hover:bg-red-600' : 'bg-red-500 group-hover:bg-red-600'"
                >
                    <ng-icon [name]="item.icon" class="text-wite" size="1.6em"/>
                </div>
                <div>
                    <span class="font-medium" [ngClass]="colorMode() === 'light' ? 'group-hover:text-gray-900' : 'group-hover:text-white'">
                        {{ item.title }}
                        <span class="absolute inset-0"></span>
                    </span>
                    @if (item.description) {
                        <p class="text-sm" [ngClass]="colorMode() === 'light' ? 'text-gray-400' : 'text-gray-300'">
                            {{ item.description }}
                        </p>
                    }
                </div>
            </a>
        }
    </div>
    <div class="grid grid-cols-1 divide-x divide-gray-900/5" [ngClass]="colorMode() === 'light' ? 'bg-gray-50' : 'bg-gray-800'">
<!--        <a href="#" class="flex items-center justify-center gap-x-2.5 p-3 font-normal " [ngClass]="colorMode() === 'light' ? 'hover:bg-gray-100 text-gray-900' : 'hover:bg-gray-600 text-white'">-->
<!--            <ng-icon name="heroPlayCircleSolid" class="text-gray-400" size="1.4em"/>-->
<!--            Bekijk de demo-->
<!--        </a>-->
        <a [routerLink]="['/', 'contact']" class="flex items-center justify-center gap-x-2.5 p-3 font-normal" [ngClass]="colorMode() === 'light' ? 'hover:bg-gray-100 text-gray-900' : 'hover:bg-gray-600 text-white'">
            <ng-icon name="heroPhoneSolid" class="text-gray-400" size="1.4em"/>
            Neem contact op
        </a>
    </div>
</div>

<div class="relative isolate">
    <!-- Background image -->
    <div
        class="absolute bg-red-50 w-full h-[calc(100%-12em)] min-h-[700px] z-0 bg-cover bg-center bg-no-repeat"
        style="background-image: url('assets/images/homepage/home-footer.jpg');"
    >
        <div class="absolute bg-black/35 w-full h-full"></div>
    </div>
    <!-- Hero content -->
    <div class="relative isolate pb-16 pt-32 sm:py-32 lg:pb-24 z-10 sm:pt-32">
        <div class="mx-auto max-w-screen-2xl px-6 2xl:px-0">
            <!-- Hero text section -->
            <div class="max-w-3xl text-left mt-10">
                <!-- Title -->
                <h1 class="text-3xl xl:text-5xl font-brand tracking-tight text-white">
                    Machines,<br>vakmensen,<br>materialen,<br>bouw- en grondstoffen
                </h1>
                <p class="mt-8 text-xl sm:text-xl sm:leading-8 text-white">
                    Real-time inzicht in vraag- en aanbod van machines, vakmensen, materialen & bouw- en grondstoffen. Door Planmeister te gebruiken minimaliseer je stilstand,  breid je jouw netwerk uit en maak je meer omzet met een minimale investering.
                </p>
                <div class="mt-12 flex items-center gap-x-6">
                    <button
                        (click)="openDownloadModal()"
                        class="sm:hidden rounded-md bg-red-600 px-5 py-3.5 text-md font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                        Download de app
                    </button>
                    <a
                        [routerLink]="['/aanbod']"
                        class="hidden sm:inline-block rounded-md bg-red-600 px-5 py-3.5 text-lg font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                        Zoek in aanbod
                    </a>
                    <a
                        [routerLink]="['/contact']"
                        class="text-sm font-semibold leading-6 text-white">
                        Neem contact op <span aria-hidden="true">→</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-white">
        <div class="mx-auto max-w-screen-2xl z-0 rounded-2xl shadow-lg shadow-gray-50 px-6 2xl:px-0">
            <div class="relative z-10">
                <div class="w-full h-12em -mt-12em rounded-t-2xl z-10">
                    <nav autoAnimate class="rounded-2xl shadow-lg px-8 py-4 w-full pointer-events-auto bg-white z-10">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <!-- Mobile view -->
                                <p class="font-lg font-semibold text-gray-500 mr-5 sm:hidden">
                                    Alle zoekresultaten in één overzicht
                                </p>

                                <!-- Desktop view -->
                                <p class="font-lg font-semibold text-gray-500 mr-5 hidden sm:block">
                                    Zoek meteen tussen aanbod en vraag
                                </p>

                                <!-- Map controls (hidden on mobile) -->
                                <div class="hidden sm:block">
                                    <homepage-map-controls/>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="h-[600px] w-full pointer-events-none xl:rounded-t-none lg:rounded-t-none md:rounded-t-none rounded-2xl  overflow-hidden -mt-10 relative z-0">
                <!-- TODO: Alle bemande machines -->
                <app-map
                    [config]="config"
                />
            </div>
        </div>
    </div>
</div>

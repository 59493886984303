<div class="p-6 bg-white border-2 rounded w-full max-w-sm mx-auto">
    <div class="flex justify-between items-center mb-6">
        <h2 class="text-xl font-bold text-gray-900">Download Planmeister 2.0</h2>
        <button (click)="closeModal.emit()" class="text-gray-500 hover:text-gray-700 p-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>

    <div class="flex flex-col items-center gap-3">
        <div class="text-center">
            <p class="text-lg text-gray-700 mb-6">
                Real-time inzicht in vraag- en aanbod van machines, vakmensen, materialen & bouw- en grondstoffen. Vanaf je telefoon of tablet!
            </p>
        </div>

        <div class="flex flex-col items-center w-full gap-4">
            <a href="https://apps.apple.com/us/app/planmeister/id1589200546" class="w-52">
                <img src="/assets/images/homepage/download_appstore.svg" alt="Download on the App Store" class="w-full h-full object-contain">
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.planmeister.app&pcampaignid=web_share" class="w-60">
                <img src="/assets/images/homepage/download_playstore.png" alt="Get it on Google Play" class="w-full h-full object-contain">
            </a>
        </div>
    </div>
</div>

<div class="block absolute h-full w-full md:hidden bg-white z-[100000] p-8">
   <app-mobile-screen-blocker />
</div>

<div class="fixed inset-0 z-0" #mapContainer>
    <app-map [config]="mapConfig"/>
</div>

<div class="pointer-events-none max-h-full">
    <div class="pt-28 pb-10 px-4 flex space-x-4 max-h-screen h-full">
        <div>
            <app-search-secondary-nav/>
        </div>
        <div class="max-h-full relative" #page>
            <router-outlet/>
        </div>
    </div>
</div>

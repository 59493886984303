import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    ViewContainerRef,
    ComponentRef,
    HostBinding
} from '@angular/core';
import { TooltipComponent } from "../components/application/tooltip/tooltip.component";

@Directive({
    selector: '[appTooltip]',
    standalone: true,
})
export class TooltipDirective {

    @Input('appTooltip')
    tooltipText: string = '';

    tooltipElement?: ComponentRef<TooltipComponent>;

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) {}

    // ----------[ Bindings ]----------

    @HostBinding('class')
    get class(): string {
        return 'relative';
    }

    // ----------[ Event Handlers ]----------

    @HostListener('mouseenter')
    onMouseEnter() {
        this.showTooltip();
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.hideTooltip();
    }

    private showTooltip() {
        const componentRef = this.viewContainerRef.createComponent(TooltipComponent);
        componentRef.instance.text.set(this.tooltipText);

        this.elementRef.nativeElement.appendChild(componentRef.location.nativeElement);

        this.tooltipElement = componentRef;
    }

    private hideTooltip() {
        if (this.tooltipElement) {
            this.tooltipElement.destroy();
            this.tooltipElement = undefined;
        }
    }
}

import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { CompanyOnboardingService } from '../../company-onboarding.service';
import { ContactInformationStep } from '../../steps/contact-information-step';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'onboarding-contact-information',
  standalone: true,
    imports: [
        ReactiveFormsModule
    ],
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.scss'
})
export class ContactInformationComponent implements OnInit, OnDestroy {
    protected readonly destroy$ = new Subject<void>();

    prevCallback = input<() => void>();
    nextCallback = input<() => void>();

    constructor(
        private onboardingService: CompanyOnboardingService,
        public contactInfoStep: ContactInformationStep
    ) {
    }

    ngOnInit() {
        const currentOnboarding = this.onboardingService.onboarding();

        if (currentOnboarding) {
            this.contactInfoStep.syncFormWithOnboarding(currentOnboarding);
        }
    }

    onSubmit() {
        if (this.contactInfoStep.form.valid) {
            const onboardingId = this.onboardingService.getCurrentOnboardingId();

            if (onboardingId) {
                this.contactInfoStep.set(onboardingId)
                    .pipe(
                        takeUntil(this.destroy$),
                        switchMap(() => this.contactInfoStep.complete(onboardingId))
                    )
                    .subscribe({
                        next: (onboarding) => {
                            this.onboardingService.updateOnboarding(onboarding);
                        },
                        error: (error) => console.error('Error setting contact information:', error)
                    });
            } else {
                console.error('No onboarding ID found');
            }
        }
    }

    protected readonly ContactInformationStep = ContactInformationStep;

    onPrevious() {
        this.onboardingService.previousStep();
    }

    onCancel() {
        this.onboardingService.cancel();
    }

    onResumeLater() {
        this.onboardingService.resumeLater();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

<app-modal-panel>
    <div body class="w-full">
        <app-image-uploader
            [autoOpen]="true"
            [allowCrop]="true"
            [aspectRatio]="imageType() === 'header' ? 16 / 9 : 1"
            (upload)="onUploadImage($event)"
        />
        @if (isUploading()) {
            <div>
                <p-progressBar [value]="uploadProgress()" />
            </div>
        } @if (uploadError()) {
        <div>
            {{ uploadError() }}
        </div>
    }
    </div>
</app-modal-panel>

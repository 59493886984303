import { Injectable, signal } from '@angular/core';

export interface Notification {
    id: string;
    type: 'success' | 'info' | 'warning' | 'error';
    message: string;
    duration?: number;
    closable?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    notifications = signal<Notification[]>([]);

    private defaultDuration = 5000; // 5 seconds

    show(notification: Omit<Notification, 'id'>): void {
        const id = this.generateUniqueId();

        const newNotification: Notification = {
            ...notification,
            id,
            duration: notification.duration ?? this.defaultDuration,
            closable: notification.closable ?? true
        };

        this.notifications.update(notifications => [...notifications, newNotification]);

        if (newNotification.duration! > 0) {
            setTimeout(() => this.remove(id), newNotification.duration);
        }

    }

    remove(id: string): void {
        this.notifications.update(notifications =>
            notifications.filter(n => n.id !== id)
        );
    }

    clear(): void {
        this.notifications.set([]);
    }

    private generateUniqueId(): string {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
}

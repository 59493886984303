import { Component, computed, signal, ViewChild } from '@angular/core';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { NgIcon } from '@ng-icons/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import {
    SearchCategorySelectComponent
} from '../../inputs/search-category-select/search-category-select.component';
import {
    SearchNavButtonComponent
} from '../../../../search/components/search-nav-button/search-nav-button.component';
import {
    SearchSupplyDateSelectComponent
} from '../../../../search/supply/components/date-select/search-supply-date-select.component';
import { Router, RouterLink } from '@angular/router';
import { SearchService } from '../../../../search/services/search.service';
import { SearchMode } from '../../../../search/typings/search-mode';
import { faBuildingMagnifyingGlass, faRightLeftLarge } from '@fortawesome/pro-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { NavAuthComponent } from '../nav-auth/nav-auth.component';
import { AuthService } from '../../../../auth/services/auth.service';
import { SearchSegment } from '../../../../search/typings/search-segment';
import { ReactiveFormsModule } from '@angular/forms';
import {
    SearchCompanyComponent
} from '../../../../search/companies/components/search-company/search-company.component';
import { NavLocationSearchComponent } from './nav-location-search/nav-location-search.component';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ModalService } from '../../../services/modal.service';
import {
    DownloadAppModalComponent
} from '../../../../pages/home/components/download-app-modal/download-app-modal.component';

@Component({
    selector: 'app-nav-bar',
    standalone: true,
    imports: [
        AutoAnimateDirective,
        NgIcon,
        NgOptimizedImage,
        SearchCategorySelectComponent,
        SearchNavButtonComponent,
        SearchSupplyDateSelectComponent,
        NgClass,
        RouterLink,
        NavMenuComponent,
        NavAuthComponent,
        ReactiveFormsModule,
        SearchCompanyComponent,
        NavLocationSearchComponent,
        FaIconComponent
    ],
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {

    @ViewChild('dateSelect') dateSelectComponent!: SearchSupplyDateSelectComponent;
    @ViewChild('locationSelect') locationSelectComponent!: NavLocationSearchComponent;
    protected readonly faRightLeftLarge = faRightLeftLarge;
    protected readonly faBars = faBars;
    protected readonly faBuildingMagnifyingGlass = faBuildingMagnifyingGlass;

    protected readonly colorMode = computed(() => this.searchService.isActive() ? 'dark' : 'light');
    protected readonly widthMode = computed(() => this.searchService.isActive() ? 'full' : 'compact');
    protected readonly navMenuActive = signal<boolean>(false);

    constructor(
        private readonly router: Router,
        private readonly searchService: SearchService,
        public readonly authService: AuthService,
        private readonly modalService: ModalService
    ) {
    }

    public hasMode(mode: SearchMode) {
        return this.searchService.isActive() && this.searchService.equals('mode', mode);
    }

    public hasSupplyMode(segment: SearchSegment | null) {
        return this.searchService.isActive() && this.searchService.equals('segment', segment);
    }

    public toggleNavMenuActive() {
        this.navMenuActive.update(active => !active);
    }

    onDateSectionClick() {
        this.dateSelectComponent.datepicker.clear();
        this.dateSelectComponent.toggleDropdown();


    }

    onLocationSelectionClick() {
        this.locationSelectComponent.clearSearch();
        this.locationSelectComponent.locationSearchInput.nativeElement.focus();


    }

    protected readonly faDownload = faDownload;

    onDownloadAppClicked() {
        const modalId = this.modalService.open({
            component: DownloadAppModalComponent,
            inputs: {},
            outputs: {
                closeModal: () => {
                    this.modalService.close(modalId);
                }
            }

        })
    }
}

<app-search-dropdown
    (selected)="selectAddressSuggestion($event)"
    [options]="addressSuggestions()"
    [mapOptionLabel]="mapOptionLabel"
    [selectedIndex]="selectedIndex()"
    [onKeyDown]="onKeyDown"
    (close)="closeDropdown()"
>
    <div trigger>
        <label [for]="id()" class="block text-sm font-medium leading-6 text-gray-900">
            <ng-content select="[label]"/>
        </label>
        <div class="relative">
            <input
                #locationSearchInput
                autocompleteOff
                [(ngModel)]="search"
                [placeholder]="placeholder()"
                [id]="id()"
                type="text"
                (click)="clearSearch()"
                (keydown)="onKeyDown($event)"
                class="outline-0 font-medium"
                role="combobox"
                [size]="search()?.length && search()!.length > 15 ? search()!.length + 5 : 20"
                aria-controls="options"
                aria-expanded="false">

        </div>
    </div>
</app-search-dropdown>

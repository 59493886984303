import { Component, computed, input, output, signal } from '@angular/core';
import {
    CompanyByIdGQL, CompleteCompanyOnboardingImageUploadGQL,
    InitiateCompanyOnboardingImageUploadGQL, PrepareImageUploadResult, Rect
} from '../../../../../../../../graphql/generated';
import { UploadProgress, UploadService } from '../../../../../../core/services/upload.service';
import { ModalService } from '../../../../../../core/services/modal.service';
import {
    ImageFile, ImageUploaderComponent
} from '../../../../../../core/components/image-uploader/image-uploader.component';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
    ModalPanelComponent
} from '../../../../../../core/components/modal-panel/modal-panel.component';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-upload-onboarding-image-modal',
  standalone: true,
    imports: [
        ImageUploaderComponent,
        ModalPanelComponent,
        ProgressBarModule
    ],
  templateUrl: './upload-onboarding-image-modal.component.html',
  styleUrl: './upload-onboarding-image-modal.component.scss'
})
export class UploadOnboardingImageModalComponent {
    // Inputs
    public readonly companyOnboardingId = input.required<string>();
    public readonly imageType = input.required<'logo' | 'header'>();

    public readonly uploadedImageId = output<string|null>();

    // State
    protected isUploading = signal(false);
    protected uploadProgress = signal(0);
    protected uploadError = signal<string | null>(null);

    // Computed
    protected canUpload = computed(() => !this.isUploading());

    constructor(
        private readonly initiateCompanyOnboardingImageUploadGQL: InitiateCompanyOnboardingImageUploadGQL,
        private readonly uploadService: UploadService,
        private readonly companyByIdGQL: CompanyByIdGQL,
        private readonly completeCompanyOnboardingImageUploadGQL: CompleteCompanyOnboardingImageUploadGQL,
        private readonly modalService: ModalService
    ) {
    }

    protected onUploadImage(imageFile: ImageFile): void {
        this.isUploading.set(true);
        this.uploadError.set(null);

        this.initiateCompanyOnboardingImageUploadGQL.mutate({
            input: {
                companyOnboardingId: this.companyOnboardingId(),
                fileName: imageFile.file.name,
                contentType: imageFile.file.type
            }
        }).pipe(
            switchMap(({ data }) => {
                if (!data?.initiateCompanyOnboardingImageUpload) {
                    throw new Error('Failed to prepare upload');
                }
                return this.uploadFile(data.initiateCompanyOnboardingImageUpload, imageFile.file);
            }),
            tap((imageId) => this.uploadedImageId.emit(imageId)),
            switchMap((imageId) => this.completeUpload(imageId, imageFile.cropParameters)),
            catchError((error) => {
                console.error('Upload failed:', error);
                this.uploadError.set('Upload failed. Please try again.');
                this.uploadedImageId.emit(null);
                return of(null);
            }),
            finalize(() => {
                this.isUploading.set(false);
                this.uploadProgress.set(0);
                this.modalService.close();
            })
        ).subscribe();
    }

    private uploadFile(uploadData: PrepareImageUploadResult, file: File): Observable<string> {
        return this.uploadService.uploadFile(
            uploadData,
            file,
            (progress: UploadProgress) => this.uploadProgress.set(progress.percentage)
        ).pipe(
            switchMap(() => of(uploadData.imageId))
        );
    }

    private completeUpload(imageId: string, cropParameters:Rect | undefined): Observable<unknown> {
        return this.completeCompanyOnboardingImageUploadGQL.mutate({
            input: {
                imageId,
                cropParameters
            }
        });
    }
}

import { Component } from '@angular/core';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import {
    HomePageHeroSectionComponent
} from './components/hero-section/home-page-hero-section.component';
import {
    HomePagePricingSectionComponent
} from './components/pricing-section/home-page-pricing-section.component';
import {
    HomePageFeatureSectionComponent
} from './components/feature-section/home-page-feature-section.component';
import {
    HomePageTestimonialSectionComponent
} from './components/testimonial-section/home-page-testimonial-section.component';
import { Footer } from '../../core/shell/footer/footer';
import {
    CompanyPublicOverviewComponent
} from '../../company/pages/public/overview/company-public-overview.component';
import {
    WeArePlanmeisterComponent
} from './components/we-are-planmeister/we-are-planmeister.component';
import { PillarsComponent } from './components/pillars/pillars.component';
import { ContactComponent } from './components/contact/contact.component';
import {
    HomePageCtaSectionComponent
} from './components/cta-section/home-page-cta-section.component';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        AutoAnimateDirective,
        NgOptimizedImage,
        RouterOutlet,
        HomePageHeroSectionComponent,
        HomePagePricingSectionComponent,
        HomePageFeatureSectionComponent,
        HomePageTestimonialSectionComponent,
        Footer,
        CompanyPublicOverviewComponent,
        WeArePlanmeisterComponent,
        PillarsComponent,
        ContactComponent,
        HomePageCtaSectionComponent
    ],
    templateUrl: './home-page.component.html'
})
export class HomePageComponent {

}

import { Component, computed, input } from '@angular/core';
import { NgIcon } from "@ng-icons/core";
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgClass } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { TooltipDirective } from "../../../core/directives/tooltip.directive";
import { SearchService } from "../../services/search.service";
import { SearchSegment } from "../../typings/search-segment";
import { SearchSecondaryNavItem } from "../../typings/search-secondary-nav-item";
import { AutoAnimateDirective } from "@knalgeel/pandora";
import {
    faExcavator,
    faHelmetSafety,
    faMagnifyingGlass,
    faMound,
    faUserHelmetSafety
} from '@fortawesome/pro-light-svg-icons';
import { faTrafficCone } from '@fortawesome/pro-light-svg-icons/faTrafficCone';

@Component({
    selector: 'app-search-secondary-nav',
    standalone: true,
    imports: [
        NgIcon,
        RouterLink,
        NgClass,
        FaIconComponent,
        TooltipDirective,
        AutoAnimateDirective
    ],
    templateUrl: './search-secondary-nav.component.html',
})
export class SearchSecondaryNavComponent {

    protected readonly mode = this.searchService.select('mode');

    protected readonly items = computed<SearchSecondaryNavItem[]>(() => [
        {
            icon: faMagnifyingGlass,
            segment: null,
            tooltip: this.mode() === 'supply' ? 'Alle aangeboden items' : (this.mode() === 'demand' ? 'Alle gevraagde items' : 'Alle bedrijven')
        },
        {
            icon: faHelmetSafety,
            segment: 'machines-bemand',
            tooltip: this.mode() === 'supply' ? 'Alle aangeboden bemande machines' : 'Alle gevraagde bemande machines',
            disabled: this.mode() === 'companies'
        },
        {
            icon: faExcavator,
            segment: 'machines-onbemand',
            tooltip: this.mode() === 'supply' ? 'Alle aangeboden onbemande machines' : 'Alle gevraagde onbemande machines',
            disabled: this.mode() === 'companies'
        },
        {
            icon: faUserHelmetSafety,
            segment: 'vakmensen',
            tooltip: this.mode() === 'supply' ? 'Alle aangeboden vakmensen' : 'Alle gevraagde vakmensen',
            disabled: this.mode() === 'companies'
        },
        {
            icon: faMound,
            segment: 'grond-bouwstoffen',
            tooltip: this.mode() === 'supply' ? 'Alle aangeboden grond en bouwstoffen' : 'Alle gevraagde grond en bouwstoffen',
            disabled: this.mode() === 'companies'
        },
        {
            icon: faTrafficCone,
            segment: 'materialen',
            tooltip: this.mode() === 'supply' ? 'Alle aangeboden materialen' : 'Alle gevraagde materialen',
            disabled: this.mode() === 'companies'
        }
    ]);

    segment = this.searchService.select('segment');

    constructor(
        private readonly searchService: SearchService,
        private readonly router: Router,
    ) {}

    // ----------[ Template Methods ]----------

    protected navigateToSegment(segment: SearchSegment | null): void {
        let route = '';
        switch (this.mode()) {
            case 'supply':
                route = 'aanbod';
                break;
            case 'demand':
                route = 'vraag';
                break;
            case 'companies':
                route = 'bedrijven';
                break;
            default:
                break;
        }

        if (this.mode() === 'supply' && this.segment() === segment && segment === null) {
            this.searchService.clearFilters();
            this.router.navigate(['/', route], { replaceUrl: true });
        } else {
            this.router.navigate(['/', route], {
                queryParams: { segment },
                queryParamsHandling: 'merge',
                replaceUrl: true
            });

            this.searchService.set('segment', segment);
        }
    }
}

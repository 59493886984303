<app-auth-wrapper>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-column">
            <div class="flex-auto">
                <div class="grid sm:grid-cols-12 grid-cols-6 gap-x-8 mx-2 space-y-6 sm:space-y-0">
                    <div class="col-span-5 items-center h-full flex mt-2">
                        <div>
                            <h3 class="text-lg font-semibold leading-6 text-gray-900" id="modal-title">Inloggen bij Planmeister</h3>
                            <div class="mt-4">
                                <p class="text-sm text-gray-500">Log in bij Planmeister om toegang te krijgen tot alle aanvragen en om je bedrijf uit te lichten met een bedrijfsprofiel.</p>
                            </div>
                            <dl class="mt-10 sm:mb-8 max-w-xl space-y-8 text-sm leading-6 text-gray-600 lg:max-w-none">
                                <div class="relative pl-9">
                                    <dt class="inline font-semibold text-gray-900">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute left-1 top-1 h-5 w-5 text-green-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"/>
                                        </svg>
                                        Wist je dat...
                                    </dt>
                                    <dd class="inline">je notificaties kunt ontvangen van nieuwe aanvragen die van toepassing zijn op jouw aanbod? Download nu de app via de App Store of Google Play Store.
                                </div>
                            </dl>
                        </div>
                    </div>

                    <div class="col-span-1">
                        <!-- Content for the middle column -->
                    </div>
                    <div class="col-span-6 flex items-center justify-center flex-col">
                        <div class="w-full space-y-6 mb-6">
                            <div>
                                <label for="email" class="block text-sm font-medium leading-6 text-black">E-mailadres</label>
                                <div class="mt-2">
                                    <input id="email"
                                           formControlName="email"
                                           type="email"
                                           autocomplete="email"
                                           required
                                           class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                                </div>
                                <div class="text-red-400 text-sm mt-1" *formControlInvalid="loginForm.get('email')">
                                    <p *formControlInvalid="loginForm.get('email'); error: 'required';">E-mailadres is verplicht.</p>
                                    <p *formControlInvalid="loginForm.get('email'); error: 'email';">Voer een geldig e-mailadres in.</p>
                                </div>
                            </div>

                            <div>
                                <div class="flex items-center justify-between">
                                    <label for="password" class="block text-sm font-medium leading-6 text-black">Wachtwoord</label>
                                </div>
                                <div class="mt-2">
                                    <input id="password"
                                           formControlName="password"
                                           type="password"
                                           autocomplete="current-password"
                                           required
                                           class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                                </div>

                                <div class="text-red-400 text-sm mt-1" *formControlInvalid="loginForm.get('password')">
                                    <p *formControlInvalid="loginForm.get('password'); error: 'required';">Wachtwoord is verplicht.</p>
                                    <p *formControlInvalid="loginForm.get('password'); error: 'minlength';">Wachtwoord moet minimaal 6 tekens lang zijn.</p>
                                </div>

                            </div>

                            @if (errorMessage()) {
                                <div class="text-red-400 text-sm">
                                    {{ errorMessage() }}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sm:flex sm:flex-row-reverse sm:pt-6 border-t border-t-gray-100">
            <div class="flex-grow sm:flex items-center gap-x-5 flex-row-reverse gap-y-4 sm:gap-y-0">
                <button
                    type="submit"
                    [disabled]="loginForm.invalid || isLoading()"
                    class="inline-flex w-full justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
                >
                    @if (isLoading()) {
                        Inloggen...
                    } @else {
                        Inloggen
                    }
                </button>

                <button
                    (click)="onCancel()"
                    type="button"
                    class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto pl-8"
                >
                    Wachtwoord vergeten?
                </button>
            </div>
            <div class="flex justify-center sm:mt-0 sm:justify-start pl-4">
                <button
                    (click)="onCancel()"
                    type="button"
                    class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto"
                >
                    Annuleren
                </button>
            </div>
        </div>
    </form>
</app-auth-wrapper>

<div
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="initializeMap($event)"
    class="h-full w-full"
>
</div>
@if(!!options.scrollWheelZoom) {
    <div class="absolute right-4 top-1/2 -translate-y-1/2 flex flex-col z-[1000]">
        <button (click)="zoomIn()" class="bg-white w-12 h-12 mb-1 rounded-md shadow-md flex items-center justify-center hover:bg-gray-100" >
            <ng-icon name="heroPlus" class="text-black" size="1.2em"></ng-icon>
        </button>
        <button (click)="zoomOut()" class="bg-white w-12 h-12 rounded-md shadow-md flex items-center justify-center hover:bg-gray-100">
            <ng-icon name="heroMinus" class="text-black" size="1.2em"></ng-icon>
        </button>
    </div>
}

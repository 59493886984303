import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { AuthService } from '../../../../auth/services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { AvatarComponent } from '@knalgeel/pandora';
import { DropdownComponent } from '../../application/dropdown/dropdown.component';
import { CompanyOnboardingService } from '../../../../company/onboarding/company-onboarding.service';
import { AuthViewService } from '../../../../auth/services/auth-view.service';

@Component({
    selector: 'app-nav-auth',
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        AvatarComponent,
        DropdownComponent
    ],
    templateUrl: './nav-auth.component.html',
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class NavAuthComponent {

    colorMode = input<'light' | 'dark'>('light');

    protected onboardingIsPresent = computed(() => {
        return this.onboardingService.currentOnboardingId() !== null;
    })

    constructor(
        public readonly authService: AuthService,
        public readonly authViewService: AuthViewService,
        private readonly router: Router,
        private readonly onboardingService: CompanyOnboardingService
    ) {
    }

    onLoginClick() {
        this.authViewService.openLoginModal();
    }

    onSignOutClick() {
        if(this.authService.isImposter()) {
            this.authService.leaveImpersonation();
        }
        this.authService.signOut();
    }

    onLeaveImpersonationClick() {
        this.authService.leaveImpersonation().subscribe(() => {
            this.router.navigate(['/']);
        });
    }

    canLeaveImpersonation = computed(() => {
        return this.authService.isImposter();
    });

    onCreateAccountClick() {
        this.onboardingService.start();

    }
}

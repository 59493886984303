import { AfterViewInit, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { CompanySearchHit, SearchCompaniesGQL } from '../../../../../../graphql/generated';
import { Subject, take, takeUntil } from 'rxjs';
import { ImageDirective } from '../../../../core/directives/image.directive';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-home-testimonial-section',
    standalone: true,
    imports: [
        ImageDirective,
        RouterLink
    ],
    templateUrl: './home-page-testimonial-section.component.html'
})
export class HomePageTestimonialSectionComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    private readonly NUMBER_OF_COMPANIES = 6;

    companySearchHits = signal<CompanySearchHit[]>([]);

    constructor(private readonly companiesGQL: SearchCompaniesGQL) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.fetchCompanies();
    }

    ngOnDestroy() {
        this.destroy$.unsubscribe();
    }

    private fetchCompanies() {
        this.companiesGQL.watch()
            .valueChanges
            .pipe(
                takeUntil(this.destroy$),
                take(1))
            .subscribe({
                next: ({ data }) => {
                    if (data?.searchCompanies?.hits?.nodes) {
                        this.companySearchHits.set(
                            this.getRandomCompanies(
                                data.searchCompanies.hits.nodes as unknown as CompanySearchHit[],
                                this.NUMBER_OF_COMPANIES
                            )
                        );
                    }
                },
                error: (error) => console.error('Error fetching companies:', error)
            })

    }

    private getRandomCompanies(companies: CompanySearchHit[], count: number): CompanySearchHit[] {
        // TODO: Optimize this heavy operation
        const shuffled = [ ...companies ].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    }
}


//create component
import { Component, OnInit } from '@angular/core';
import { Footer } from '../../core/shell/footer/footer';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'page-terms-and-conditions',
    standalone: true,
    imports: [
        Footer
    ],
    templateUrl: './terms-and-conditions.component.html'
})
export class TermsAndConditionsComponent implements OnInit {
    safePdfUrl!: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdf/algemene-voorwaarden.pdf');
    }
}

import { AuthService } from "../../auth/services/auth.service";
import { inject } from "@angular/core";
import { catchError, of } from "rxjs";

export function currentUserFromLocalStorageInitializer() {
    const authService = inject(AuthService);

    return () => {
        if (!authService.hasToken()) {
            return of(true);
        }

        return authService.initializeProfile().pipe(
            catchError(error => {
                console.error('Error initializing user:', error);
                return of(false);
            })
        );
    }
}

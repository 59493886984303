import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache, split, from } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { NotificationService } from "@knalgeel/pandora";
import { setContext } from '@apollo/client/link/context';
import {AuthService} from "../app/auth/services/auth.service";

// TODO: ENV
const httpUri = 'https://soil.planmeister.com/graphql';
const wsUri = 'wss://soil.planmeister.com/graphql';

const wsLink = new GraphQLWsLink(createClient({
    url: wsUri,
    connectionParams: () => {
        const token = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
        return token ? { Authorization: `Bearer ${token}` } : {};
    },
}));

export function createApollo(httpLink: HttpLink, notificationService: NotificationService): ApolloClientOptions<any> {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            const message = graphQLErrors[0].message;
            notificationService.add({
                type: 'error',
                message,
            });
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            notificationService.add({
                type: 'error',
                message: networkError.message,
            });
        }
    });

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        };
    });

    const httpLinkWithError = from([
        errorLink,
        authLink,
        httpLink.create({
            uri: httpUri,
        })
    ]);

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLinkWithError
    );

    return {
        link: splitLink,
        cache: new InMemoryCache(),
        devtools: {
            enabled: true,
        },
        defaultOptions: {
        }
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, NotificationService],
        },
    ],
})
export class GraphQLModule {}

import { Component } from '@angular/core';
import {
    CompanyOnboardingIntroComponent
} from '../components/intro/company-onboarding-intro.component';
import { CompanyOnboardingService, OnboardingStep } from '../company-onboarding.service';
import {
    BasicInformationComponent
} from '../components/basic-information/basic-information.component';
import { StepperModule } from 'primeng/stepper';
import { StepWrapperComponent } from '../components/step-wrapper/step-wrapper.component';

@Component({
  selector: 'onboarding-wrapper',
  standalone: true,
    imports: [
        CompanyOnboardingIntroComponent,
        BasicInformationComponent,
        StepperModule,
        StepWrapperComponent
    ],
  templateUrl: './onboarding-wrapper.component.html',
  styleUrl: './onboarding-wrapper.component.scss'
})
export class OnboardingWrapperComponent {
    currentStep = this.onboardingService.currentStep;

    constructor(private onboardingService: CompanyOnboardingService) {}

    protected readonly OnboardingStep = OnboardingStep;
}

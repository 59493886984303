@if (!authService.isLoading()) {
    <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
        @if (authService.isAuthenticated()) {
            <app-dropdown>
                <dropdown-trigger>
                    <div
                        [ngClass]="colorMode() === 'light'
                                 ? 'text-gray-800 hover:text-gray-900'
                                  : 'text-white hover:text-gray-100'">
                        <button
                            type="button"
                            class="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 text-sm font-medium" id="menu-button" aria-expanded="true" aria-haspopup="true">
                            <span class="hidden 2xl:inline-block">Ingelogd als </span>{{ authService.currentUser()?.firstName }}
                            <svg
                                class="-mr-1 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                </dropdown-trigger>
                <dropdown-content>
                    <div class="px-4 py-3" role="none">
                        <p class="text-sm" role="none">Ingelogd bij</p>
                        <p class="truncate text-sm font-semibold text-gray-900" role="none">
                            {{ authService.currentEmployee()?.company?.name }}
                        </p>
                    </div>
                    <!--                    <div class="py-1" role="none">-->
                    <!--                        &lt;!&ndash; Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" &ndash;&gt;-->
                    <!--                        <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>-->
                    <!--                        <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>-->
                    <!--                        <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-2">License</a>-->
                    <!--                    </div>-->
                    @if(canLeaveImpersonation()) {
                        <div class="py-1" role="none">
                            <button
                                (click)="onLeaveImpersonationClick()"
                                class="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-4">
                                Terug naar eigen account
                            </button>
                        </div>
                    }
                    <div class="py-1" role="none">
                        <button
                            (click)="onSignOutClick()"
                            class="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-3">
                            Uitloggen
                        </button>

                    </div>
                </dropdown-content>
            </app-dropdown>
            <span [ngClass]="colorMode() === 'light' ? 'bg-gray-200' : 'bg-gray-700'" class="h-6 w-px" aria-hidden="true"></span>
        } @else {
            <button
                [ngClass]="colorMode() === 'light'
                         ? 'text-gray-800 hover:text-gray-900'
                          : 'text-white hover:text-gray-200'"
                (click)="onLoginClick()"
                class="text-sm font-medium"
            >
                Inloggen
            </button>
            <span [ngClass]="colorMode() === 'light' ? 'bg-gray-200' : 'bg-gray-700'" class="h-6 w-px" aria-hidden="true"></span>
            @if (onboardingIsPresent()) {
                <button
                    (click)="onCreateAccountClick()"
                    class="text-sm font-medium cursor-pointer underline"
                    [ngClass]="colorMode() === 'light'
                        ? 'text-gray-800 hover:text-gray-7900'
                         : 'text-white hover:text-gray-200'">
                    Account aanmaken hervatten
                </button>
            } @else {
                <button
                    (click)="onCreateAccountClick()"
                    class="text-sm font-medium cursor-pointer"
                    [ngClass]="colorMode() === 'light'
                        ? 'text-gray-800 hover:text-gray-900'
                         : 'text-white hover:text-gray-200'">
                    Acccount aanmaken
                </button>
            }
            <span [ngClass]="colorMode() === 'light' ? 'bg-gray-200' : 'bg-gray-700'" class="h-6 w-px" aria-hidden="true"></span>
            <button
                class="text-sm font-medium"
                [ngClass]="colorMode() === 'light'
                        ? 'text-gray-800 hover:text-gray-900'
                         : 'text-white hover:text-gray-200'"
                [routerLink]="['/contact']"
            >
                Contact
            </button>
            <span class="h-6 w-px" aria-hidden="true"></span>
        }
    </div>
}

import { Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { LocationInput } from '../../../../../../graphql/generated';
import { LocationStep } from '../../steps/location-step';
import { ReactiveFormsModule } from '@angular/forms';
import {
    AddressSearchDropdownComponent
} from '../../../../core/components/inputs/address-search-dropdown/address-search-dropdown.component';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { CompanyOnboardingService } from '../../company-onboarding.service';

@Component({
  selector: 'onboarding-location',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        AddressSearchDropdownComponent
    ],
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss'
})
export class LocationComponent implements OnInit, OnDestroy {
    public readonly destroy$ = new Subject<void>();

    initialAddress = signal<string | null>(null);

    prevCallback = input<() => void>();
    nextCallback = input<() => void>();

    constructor(
        private onboardingService: CompanyOnboardingService,
        public locationStep: LocationStep
    ) {
    }

    ngOnInit() {
        const currentOnboarding = this.onboardingService.onboarding();

        if (currentOnboarding) {
            this.locationStep.syncFormWithOnboarding(currentOnboarding);

            this.locationStep.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
                next: (values) => {
                    console.log('Location form values:', values);
                    this.locationStep.isValid.next(this.locationStep.form.valid);
                }
            });

            this.locationStep.getLocationState()?.pipe(takeUntil(this.destroy$)).subscribe({
                next: (location) => {
                    if (location) {
                        this.initialAddress.set(location.location?.properties.formattedAddress || null);
                    }
                }
            });
        }
    }

    onAddressSelected(locationInput: LocationInput | null) {
        if (locationInput) {
            this.locationStep.form.patchValue({
                locationInput
            });
        }
    }

    onSubmit() {
        if (this.locationStep.form.valid) {
            const onboardingId = this.onboardingService.getCurrentOnboardingId();

            if (onboardingId) {
                this.locationStep.set(onboardingId)
                    .pipe(
                        takeUntil(this.destroy$),
                        switchMap(() => this.locationStep.complete(onboardingId))
                    )
                    .subscribe({
                        next: (onboarding) => {
                            this.onboardingService.updateOnboarding(onboarding);
                        },
                        error: (error) => console.error('Error setting contact information:', error)
                    });
            } else {
                console.error('No onboarding ID found');
            }
        }
    }

    protected readonly LocationStep = LocationStep;

    onPrevious() {
        this.onboardingService.previousStep();
    }

    onCancel() {
        this.onboardingService.cancel();
    }

    onResumeLater() {
        this.onboardingService.resumeLater();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

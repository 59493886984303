import { Component } from '@angular/core';
import { CompanySearchService } from '../../company-search.service';
import { FormsModule } from '@angular/forms';
import { NgIcon } from '@ng-icons/core';

@Component({
    selector: 'app-search-company',
    standalone: true,
    imports: [
        FormsModule,
        NgIcon
    ],
    templateUrl: `./search-company.component.html`
})
export class SearchCompanyComponent {
    constructor(
        public readonly companySearchService: CompanySearchService
    ) {
    }
}

<form [formGroup]="contactInfoStep.form" (ngSubmit)="onSubmit()">
    <div class="flex flex-column">
        <div class="flex-auto">
            <div class="grid grid-cols-12 gap-x-8 mx-2 my-10">
                <div class="col-span-5 items-center h-full flex">
                    <div>
                        <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Vul je contactinformatie in</h3>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">Aan de hand van deze contactinformatie kunnen andere bedrijven je bereiken.</p>
                        </div>
                        <dl class="mt-10 max-w-xl space-y-8 text-sm leading-6 text-gray-600 lg:max-w-none">
                            <div class="relative pl-9">
                                <dt class="inline font-semibold text-gray-900">
                                    <svg class="absolute left-1 top-1 h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                                    </svg>
                                    Krijg meteen toegang tot alle aanvragen
                                </dt>
                                <dd class="inline">Met een Planmeister-account krijg je inzicht in de markt. Bekijk wie er bij jou in de buurt aanvragen uitzetten en reageer direct.</dd>
                            </div>
                            <div class="relative pl-9">
                                <dt class="inline font-semibold text-gray-900">
                                    <svg class="absolute left-1 top-1 h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                    </svg>
                                    Licht je bedrijf uit met een bedrijfsprofiel
                                </dt>
                                <dd class="inline">Met een bedrijfsprofiel bemachtig je jouw plek op Planmeister. Licht je machines, materialen, vakmensen en bouw- en grondstoffen uit en maak meer omzet.</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="col-span-1">
                    <!-- Content for the middle column -->
                </div>
                <div class="col-span-6 my-6">
                    <div class="mb-4">
                        <label for="email" class="block text-sm font-medium text-gray-700 mb-2">E-mailadres bedrijf</label>
                        <input type="email" required id="email" formControlName="email"
                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                        @if (contactInfoStep.form.get('email')?.invalid && contactInfoStep.form.get('email')?.touched) {
                            <p class="mt-1 text-sm text-red-600">Het e-mailadres is niet geldig.</p>
                        }
                    </div>
                    <div class="mb-4">
                        <label for="phoneNumber" class="block text-sm font-medium text-gray-700 mb-2">Telefoonnummer</label>
                        <input type="tel" required id="phoneNumber" formControlName="phoneNumber"
                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                        @if (contactInfoStep.form.get('phoneNumber')?.invalid && contactInfoStep.form.get('phoneNumber')?.touched) {
                            <p class="mt-1 text-sm text-red-600">Het telefoonnummer is niet geldig.</p>
                        }
                    </div>
                    <div class="mb-4">
                        <label for="plannerPhoneNumber" class="block text-sm font-medium text-gray-700 mb-2">Telefoonnummer planner<span class="text-gray-500"> (optioneel)</span></label>
                        <input type="tel" id="plannerPhoneNumber" formControlName="plannerPhoneNumber"
                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                        @if (contactInfoStep.form.get('plannerPhoneNumber')?.invalid && contactInfoStep.form.get('plannerPhoneNumber')?.touched) {
                            <p class="mt-1 text-sm text-red-600">Het telefoonnummer is niet geldig.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:flex sm:flex-row-reverse sm:pt-6 border-t border-t-gray-100">
        <button
            type="submit" [disabled]="!contactInfoStep.form.valid"
            class="inline-flex w-full justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
        >
            Doorgaan naar bedrijfslocatie
        </button>
<!--                <button (click)="onPrevious()" type="button"-->
<!--                        class="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">-->
<!--                    Terug-->
<!--                </button>-->

        <div class="flex-grow">
            <div class="flex justify-center mt-3 pl-4 sm:mt-0 sm:justify-start">
                <button
                    (click)="onResumeLater()"
                    type="button"
                    class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto pr-8 border-r border-gray-200"
                >
                    Later hervatten
                </button>
                <button
                    (click)="onCancel()"
                    type="button"
                    class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto ml-8"
                >
                    Annuleren
                </button>
            </div>
        </div>
    </div>
</form>

import { Component, signal } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import {
    DynamicDialogContentComponent
} from '../dynamic-component/dynamic-dialog-content.component';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { ClickStopDirective } from '../../../directives/click-stop.directive';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { Modal, ModalOptions } from '../../../../types/modal';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-modal-container',
    standalone: true,
    imports: [
        DynamicDialogContentComponent,
        ClickOutsideDirective,
        ClickStopDirective,
        AutoAnimateDirective
    ],
    templateUrl: './modal-container.component.html',
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({ opacity: 0 }))
            ])
        ]),
        trigger('modalEnterExit', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(-20px)' }),
                animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-20px)' }))
            ])
        ])
    ]
})

export class ModalContainerComponent {

    protected readonly options = signal<ModalOptions | null>(null);

    // ----------[ Dependencies ]----------

    public constructor(
        private readonly modalService: ModalService
    ) {
    }

    // ----------[ Template Methods ]----------

    public get stack() {
        return this.modalService.stack;
    }

    public get topModal() {
        return this.stack[this.stack.length - 1];
    }

    // ----------[ Getters ]----------

    protected closeModal() {
        if (!this.topModal?.options?.closeOnBackdropClick) {
            return;
        }

        this.modalService.close();
    }

    protected initializeModal(modal: Modal, instance: any) {
        modal.onInit?.(instance);
    }

}

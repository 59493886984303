import { Component, DestroyRef, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { NavBarComponent } from './core/components/navigation/nav-bar/nav-bar.component';
import { fadeAnimation } from './core/animations';
import { NotificationsComponent } from './core/shell/notifications/notifications.component';
import {
    ModalContainerComponent
} from './core/components/application/modal-container/modal-container.component';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import LaraPlanmeister from '../theme/LaraPlanmeister';
import { CompanyOnboardingService } from './company/onboarding/company-onboarding.service';
import { ScrollBehaviorService } from './core/services/scroll-behavior.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { inject as injectAnalytics } from '@vercel/analytics'
import { injectSpeedInsights } from '@vercel/speed-insights'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [ CommonModule, RouterOutlet, AutoAnimateDirective, RouterLink, NgOptimizedImage, NavBarComponent, NotificationsComponent, ModalContainerComponent, ToastModule ],
    templateUrl: './app.component.html',
    animations: [ fadeAnimation ],
    providers: [MessageService]

})
export class AppComponent implements OnInit {
    title = 'planmeister-site';

    // ----------[ Dependencies ]----------

    constructor(
        private readonly primeNgConfig: PrimeNGConfig,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly destroyRef: DestroyRef,
        private readonly onboardingService: CompanyOnboardingService,
        private readonly googleTagManagerService: GoogleTagManagerService,
        private readonly scrollBehaviorService: ScrollBehaviorService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['onboarding']) {
                this.onboardingService.start();
            }
        });

        injectAnalytics();

        const speedInsights = injectSpeedInsights({
            framework: 'angular',
        })

        // detect route changes and set the speed insights route
        this.router.events.pipe(
            takeUntilDestroyed(destroyRef)
        ).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                speedInsights?.setRoute(event.url);
            }
        });

        this.primeNgConfig.theme.set({
            preset: LaraPlanmeister,
            options: {
                darkModeSelector: '.dark',
                cssLayer: {
                    name: 'primeng',
                    order: 'tailwind-base, primeng, tailwind-utilities'
                }

            }
        });

        this.googleTagManagerService.addGtmToDom();

        // Set custom translations in dutch
        this.primeNgConfig.setTranslation({
            dayNames: [ 'zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag' ],
            dayNamesShort: [ 'zo', 'ma', 'di', 'wo', 'do', 'vr', 'za' ],
            dayNamesMin: [ 'zo', 'ma', 'di', 'wo', 'do', 'vr', 'za' ],
            monthNames: [ 'januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december' ],
            monthNamesShort: [ 'jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec' ],
            today: 'Vandaag',
            clear: 'Leegmaken',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Wk',
            chooseYear: 'Jaar kiezen',
            prevYear: 'Vorig jaar',
            nextYear: 'Volgend jaar',
            prevMonth: 'Vorige maand',
            nextMonth: 'Volgende maand',
            prevDecade: 'Vorig decennium',
            nextDecade: 'Volgend decennium',
            prevSecond: 'Vorige seconde',
            nextSecond: 'Volgende seconde',
            prevHour: 'Vorig uur',
            nextHour: 'Komend uur',
            prevMinute: 'Vorige minuut',
            nextMinute: 'Volgende minuut'
        })
    }

    // ----------[ Lifecycle Hooks ]----------

    public ngOnInit() {
        function updateViewportUnits() {
            let vh = window.innerHeight * 0.01;
            let vw = window.innerWidth * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            document.documentElement.style.setProperty('--vw', `${vw}px`);
        }

        window.addEventListener('resize', updateViewportUnits);
        updateViewportUnits();
    }

    // ----------[ Template Helpers ]----------

    protected prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}

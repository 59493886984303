import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, throwError } from 'rxjs';
import { OnboardingStep } from './onboarding-step';
import {
    CompanyOnboardingFragment,
    RegisterUserAndCompleteOnboardingGQL
} from '../../../../../graphql/generated';
import { map } from 'rxjs/operators';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { CompanyOnboardingService } from '../company-onboarding.service';
import { phoneNumberValidator } from '../../../core/validators/phone-number-validator';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { AuthService } from '../../../auth/services/auth.service';

@Injectable({ providedIn: 'root' })
export class UserStep implements OnboardingStep {
    stepIndex = 1;
    form!: FormGroup;
    isValid = new BehaviorSubject<boolean>(false);

    constructor(
        private fb: FormBuilder,
        private onboardingService: CompanyOnboardingService,
        private registerUserAndCompleteOnboarding: RegisterUserAndCompleteOnboardingGQL,
        private readonly authService: AuthService
    ) {
        this.initForm();
    }

    initForm(): void {
        this.form = this.fb.group({
            firstName: [ '', [ Validators.required ] ],
            lastName: [ '', [ Validators.required ] ],
            password: [ '', [
                Validators.required,
                Validators.minLength(8),
                this.passwordRequirementsValidator
            ] ],
            passwordConfirmation: [ '', [ Validators.required ] ],
            email: [ '', [ Validators.required, Validators.email ] ],
            phoneNumber: [ '', [ Validators.required, phoneNumberValidator({
                regionCode: 'NL',
                formatOnValid: true,
                format: PhoneNumberFormat.E164
            }) ] ]
        }, { validators: this.passwordMatchValidator });

        this.form.statusChanges.subscribe(status => {
            this.isValid.next(status === 'VALID');
        });
    }

    passwordMatchValidator(form: FormGroup): ValidationErrors | null {
        const password = form.get('password');
        const passwordConfirmation = form.get('passwordConfirmation');

        if (password && passwordConfirmation && password.value !== passwordConfirmation.value) {
            return { passwordMismatch: true };
        }

        return null;
    }

    passwordRequirementsValidator(control: AbstractControl): ValidationErrors | null {
        const password = control.value;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumeric = /[0-9]/.test(password);

        const valid = hasUpperCase && hasLowerCase && hasNumeric && password.length >= 8;

        if (!valid) {
            return { passwordRequirements: true };
        }

        return null;
    }

    syncFormWithOnboarding(onboarding: CompanyOnboardingFragment): void {
        // We don't need to sync the form with the onboarding
    }

    set(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (!this.isValid.value) {
            return throwError(() => new Error('Contact information step is not valid'));
        }

        return this.registerUserAndCompleteOnboarding.mutate({
            input: {
                companyOnboardingId: onboardingId,
                firstName: this.form.value.firstName,
                lastName: this.form.value.lastName,
                password: this.form.value.password,
                email: this.form.value.email,
                phoneNumber: this.form.value.phoneNumber
            }
        }).pipe(
            map(result => {
                const completeResult = result.data?.registerUserAndCompleteOnboarding;
                if (!completeResult) {
                    throw new Error('Failed to set contact information');
                }
                return completeResult;
            }),
            // Just to comply with the interface
            switchMap(() => this.onboardingService.onboarding$ as Observable<CompanyOnboardingFragment>)
        );
    }

    complete(onboardingId: string): Observable<CompanyOnboardingFragment> {
        return this.onboardingService.onboarding$ as Observable<CompanyOnboardingFragment>;
    }
}

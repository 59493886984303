<div class="bg-white">
    <main class="mx-auto w-full max-w-screen-2xl px-6 2xl:px-0 pb-16 pt-44 lg:pt-48 sm:pb-24">
        <div class="flex items-center justify-center flex-1 py-8">
            <div class="lg:py-16 w-full">
                <h1 class="font-brand text-brand-black font-bold md:mb-16 sm:text-5xl md:text-7xl lg:text-8xl xl:text-7xl mb-8 text-4xl">Nee maar <br>goed dit is<br>overmacht.</h1>
                <h2 class="md:text-4xl text-brand-black md:mb-16 mb-8 text-2xl leading-none">
                    Pagina niet gevonden. <span class="text-gray-200">404.</span>
                </h2>
                <button [routerLink]="'/'" class="bg-red-600 text-white px-6 py-3 rounded-md text-lg font-semibold">Terug naar de homepagina
                </button>
            </div>
        </div>
    </main>
</div>

<shell-footer></shell-footer>

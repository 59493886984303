<form [formGroup]="basicInfoStep.form" (ngSubmit)="onSubmit()">
    <div class="flex flex-column">
        <div class="flex-auto">
            <div class="grid grid-cols-12 gap-x-8 mx-2">
                <div class="col-span-5 items-center h-full flex">
                    <div>
                        <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Vul je bedrijfsgegevens in</h3>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">Aan de hand van je KvK-nummer hebben we alvast wat gegevens ingevuld.</p>
                        </div>
                        <dl class="mt-10 max-w-xl space-y-8 text-sm leading-6 text-gray-600 lg:max-w-none">
                            <div class="relative pl-9">
                                <dt class="inline font-semibold text-gray-900">
                                    <svg class="absolute left-1 top-1 h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                                    </svg>
                                    Krijg meteen toegang tot alle aanvragen
                                </dt>
                                <dd class="inline">Met een Planmeister-account krijg je inzicht in de markt. Bekijk wie er bij jou in de buurt aanvragen uitzetten en reageer direct.</dd>
                            </div>
                            <div class="relative pl-9">
                                <dt class="inline font-semibold text-gray-900">
                                    <svg class="absolute left-1 top-1 h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                    </svg>
                                    Licht je bedrijf uit met een bedrijfsprofiel
                                </dt>
                                <dd class="inline">Met een bedrijfsprofiel bemachtig je jouw plek op Planmeister. Licht je machines, materialen, vakmensen en bouw- en grondstoffen uit en maak meer omzet.</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="col-span-1">
                    <!-- Content for the middle column -->
                </div>
                <div class="col-span-6 my-6">
                    <div class="mb-4">
                        <label for="companyName" class="block text-sm font-medium text-gray-700 mb-2">Bedrijfsnaam</label>
                        <input type="text" id="companyName" formControlName="companyName"
                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">

                        @if (basicInfoStep.form.get('companyName')?.invalid && basicInfoStep.form.get('companyName')?.touched) {
                            <p class="mt-1 text-sm text-red-600">Een bedrijfsnaam is verplicht.</p>
                        }
                    </div>
                    <div class="mb-4">
                        <label for="description" class="block text-sm font-medium text-gray-700 mb-2">Een beschrijving van je diensten</label>
                        <textarea id="description" formControlName="description" rows="3"
                                  class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"></textarea>
                        @if (basicInfoStep.form.get('description')?.invalid && basicInfoStep.form.get('description')?.touched) {
                            <p class="mt-1 text-sm text-red-600">Een beschrijving van je diensten is verplicht.</p>
                        }
                    </div>
                    <div class="mb-4">
                        <label for="slug" class="block text-sm font-medium text-gray-700">Profiel-url</label>
                        <div class="mt-2 w-full">
                            <div class="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-red-600">
                                <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm">www.planmeister.com/bedrijven/</span>
                                <input type="text" id="slug" formControlName="slug" class="block flex-1 border-0 bg-transparent py-1.5 pl-0 text-gray-900 placeholder:text-gray-200 focus:ring-0 sm:text-sm sm:leading-6"
                                       placeholder="bedrijfsnaam">
                            </div>
                            @if (basicInfoStep.form.get('slug')?.invalid && basicInfoStep.form.get('slug')?.touched) {
                                <p class="mt-1 text-sm text-red-600">Deze profiel-url is niet geldig.</p>
                            }
                        </div>
                    </div>
                    <div class="mb-4">
                        <label for="website" class="block text-sm font-medium text-gray-700 mb-2">Website</label>
                        <input type="url" id="website" formControlName="website" placeholder="www.bedrijf.nl"
                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                        @if (basicInfoStep.form.get('website')?.invalid && basicInfoStep.form.get('website')?.touched) {
                            <p class="mt-1 text-sm text-red-600">Deze website is niet geldig. Vergeet niet de volledige url (inclusief https://) in te voeren.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:flex sm:flex-row-reverse sm:pt-6 border-t border-t-gray-100">
        <button
            type="submit" [disabled]="!basicInfoStep.form.valid"
            class="inline-flex w-full justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
        >
            Doorgaan naar contactinformatie
        </button>
<!--        <button (click)="onClose()" type="button"-->
<!--                class="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">-->
<!--            Terug-->
<!--        </button>-->

        <div class="flex-grow">
            <div class="flex justify-center mt-3 pl-4 sm:mt-0 sm:justify-start">
                <button
                    (click)="onResumeLater()"
                    type="button"
                    class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto pr-8 border-r border-gray-200"
                >
                    Later hervatten
                </button>
                <button
                    (click)="onCancel()"
                    type="button"
                    class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto ml-8"
                >
                    Annuleren
                </button>
            </div>
        </div>
    </div>
</form>

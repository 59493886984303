<input
    [ngModel]="companySearchService.query()"
    (ngModelChange)="companySearchService.query.set($event)"
    #input
    type="text"
    class="w-full py-1.5 pr-1 outline-0"
    placeholder="Alle bedrijven"
/>
<div class="absolute pointer-events-none inset-0 flex items-center justify-end">
    <ng-icon name="HeroMagnifyingGlassSolid"
             size="1.2em"
    />
</div>

'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
var prefix = 'fal';
var iconName = 'earth-europe';
var width = 512;
var height = 512;
var aliases = ["globe-europe"];
var unicode = 'f7a2';
var svgPathData = 'M477.7 288l-38.3 0c-6.2 0-12.3-2.2-17.1-6.2l-15.9-13.2c-4.6-3.8-5.2-10.5-1.5-15.2c14.6-18.3 1.6-45.4-21.8-45.4l-3.7 0c-6.3 0-11.4-5.1-11.4-11.4l0-9.4c0-18.4-22.2-27.6-35.2-14.6l-32.1 32.1c-2.6 2.6-6.8 2.6-9.4 0l-2.8-2.8c-6.3-6.3-14.8-9.8-23.7-9.8c-10.5 0-20.5 5-26.8 13.4l-10.7 14.3c-2 2.7-5.2 4.3-8.5 4.3c-5.9 0-10.7-4.8-10.7-10.7l0-1.6c0-3.3 1.3-6.4 3.6-8.7l18.7-18.7c6.2-6.2 9.7-14.7 9.7-23.5c0-4.3 2.4-8.2 6.2-10.1l59.2-29.6c20.2-10.1 26.9-35.6 14.2-54.3c-8.9-13.2-25.1-19.1-40-15.3l4.5-17.8C394.5 47.5 480 141.8 480 256c0 10.9-.8 21.5-2.3 32zm-7 32c-3.3 11-7.4 21.7-12.3 32l-19.8 0c-10.2 0-20.1-4.1-27.3-11.3l-4-4C396.6 326 382.2 320 367.1 320c-5.9 0-11.7-2.1-16.1-6l-26.6-22.8c-14.5-12.4-33-19.3-52.1-19.3l-20.9 0c-15.8 0-31.2 4.7-44.4 13.4l-27.4 18.3c-22.3 14.8-35.6 39.8-35.6 66.6l0 3.2c0 21.2 8.4 41.6 23.4 56.6l16 16c11.5 11.5 27.1 17.9 43.3 17.9c11.4 0 21.2 6.5 26.1 16C130.6 478.3 32 378.6 32 256C32 133.8 129.9 34.4 251.5 32l-6.9 27.6c-4.9 19.7 15.4 36.2 33.7 27.3l8.1-3.9c2.4-1.2 5.3-.4 6.8 1.8c1.8 2.7 .8 6.3-2 7.8l-59.2 29.6c-14.7 7.3-23.9 22.3-23.9 38.7c0 .3-.1 .6-.4 .9L189 180.4c-8.3 8.3-13 19.6-13 31.3l0 1.6c0 23.6 19.1 42.7 42.7 42.7c13.4 0 26.1-6.3 34.1-17.1l10.7-14.3c.3-.4 .7-.6 1.2-.6c.4 0 .8 .2 1.1 .4l2.8 2.8c15.1 15.1 39.6 15.1 54.7 0l15.4-15.4c5.8 15.3 20 26.5 36.9 28c-9.5 17.6-5.6 40 10.3 53.3l15.9 13.2c10.5 8.8 23.8 13.6 37.5 13.6l31.3 0zm-30.9 64c-35.1 50.3-90.2 85.5-153.8 94c-6.8-26.4-30.8-46-59.3-46c-7.8 0-15.2-3.1-20.7-8.6l-16-16c-9-9-14.1-21.2-14.1-33.9l0-3.2c0-16.1 8-31 21.4-39.9l27.4-18.3c7.9-5.3 17.1-8.1 26.6-8.1l20.9 0c11.5 0 22.5 4.1 31.2 11.6l26.6 22.8c10.3 8.8 23.4 13.7 37 13.7c6.6 0 12.9 2.6 17.5 7.3l4 4c13.2 13.2 31.2 20.7 49.9 20.7l1.2 0zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM187.3 100.7c-6.2-6.2-16.4-6.2-22.6 0l-32 32c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l32-32c6.2-6.2 6.2-16.4 0-22.6z';
exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};
exports.faEarthEurope = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
import { definePreset } from 'primeng/themes';
import { Lara } from 'primeng/themes/lara';

export default definePreset(Lara, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        }
    },
    components: {
        datepicker: {
            root: {
                fontSize: '0.875rem',
            },
            panel: {
                padding: '0.5rem', // Reduced from 1rem
            },
            header: {
                padding: '0.01rem',
                margin: '0.01rem',
                fontSize: '1rem',
            },
            weekDay: {
                fontSize: '0.75rem', // Font size for the weekday names
            },
            date: {
                selected: {
                    background: 'rgb(250,74,78)'
                },
                fontSize: '0.75rem',
                width: '2rem', // Reduced from 2.5rem
                height: '2rem', // Reduced from 2.5rem
                padding: '0.25rem', // Reduced from 0.375rem
            },
            dayView: {
                margin: '0.5rem 0px 0px 0px', // Reduced from 0.75rem
            },
            monthView: {
                margin: '0.5rem 0px 0px 0px', // Reduced from 0.75rem
            },
            yearView: {
                margin: '0.5rem 0px 0px 0px', // Reduced from 0.75rem
            },
            buttonbar: {
                fontSize: '0.875rem', // Font size for buttons in button bar
                padding: '0.5rem 0px 0px 0px', // Reduced from 0.75rem
            },
            timePicker: {
                fontSize: '0.875rem', // Font size for time picker
                padding: '0.5rem 0px 0px 0px', // Reduced from 0.75rem
                gap: '0.25rem', // Reduced from 0.5rem
            },
            weekNumbers:{
                background: 'rgb(250,74,78)',
                fontSize: '0.75rem', // Font size for week numbers
                color: 'white',
            },
            month: {
                fontSize: '0.875rem', // Font size for month names in month view
            },
            year: {
                fontSize: '0.875rem', // Font size for years in year view
            },
        },
    }
})

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Notification, NotificationService } from '../../services/notification.service';

@Component({
    selector: 'shell-notifications',
    standalone: true,
    imports: [ CommonModule ],
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent {
    constructor(
        public readonly notificationService: NotificationService
    ) {
    }

    closeNotification(id: string): void {
        this.notificationService.remove(id);
    }

    getIconPath(type: Notification['type']): string {
        switch (type) {
            case 'success':
                return 'M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z';
            case 'info':
                return 'M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z';
            case 'warning':
                return 'M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z';
            case 'error':
                return 'M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z';
            default:
                return '';
        }
    }

    getTitle(type: Notification['type']): string {
        switch (type) {
            case 'success':
                return 'Gelukt';
            case 'info':
                return 'Informatie';
            case 'warning':
                return 'Waarschuwing';
            case 'error':
                return 'Fout';
            default:
                return '';
        }
    }
}

import {
    ApplicationRef,
    Component,
    createComponent,
    Injector,
    input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { circle, Circle, Map as LeafletMap, MapOptions, MarkerClusterGroup, Popup } from 'leaflet';
import { MapConfig } from '../../../types/search/map-config';
import { MapService } from '../../../search/services/map.service';
import 'leaflet.markercluster';
import { makeMarkerClusterGroup } from '../../factories/search/make-marker-cluster-group';
import { MapMarker } from '../../../types/search/map-marker';
import { makeMapOptions } from '../../factories/search/make-map.options';
import {
    DynamicDialogContentComponent
} from '../application/dynamic-component/dynamic-dialog-content.component';
import { NgIcon } from '@ng-icons/core';
import { Subject, takeUntil } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-map',
    standalone: true,
    imports: [
        LeafletModule,
        NgIcon
    ],
    templateUrl: './map.component.html',
    styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    public readonly config = input.required<MapConfig>();

    // ----------[ Template Properties ]----------

    protected options!: MapOptions;

    private map!: LeafletMap;

    private markerClusterGroup!: MarkerClusterGroup;

    private circle: Circle | null = null;

    // ----------[ Dependencies ]----------

    constructor(
        private readonly applicationRef: ApplicationRef,
        private readonly mapService: MapService,
        private readonly injector: Injector,
        private readonly router: Router
    ) {
    }

    // ----------[ Lifecycle ]----------

    public ngOnInit(): void {
        // this.router.events.pipe(
        //     takeUntil(this.destroy$)
        // ).subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //         this.clearMarkers();
        //     }
        // });

        this.options = makeMapOptions(this.config());
        this.markerClusterGroup = makeMarkerClusterGroup();

        this.mapService.clearedMarkers
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.clearMarkers());

        this.mapService.markerAdded
            .pipe(takeUntil(this.destroy$))
            .subscribe(marker => this.addMarker(marker));

        this.mapService.scrollTo
            .pipe(takeUntil(this.destroy$))
            .subscribe(coordinates => this.scrollTo(coordinates));

        this.mapService.zoomTo
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ lat, lng, zoom }) => {
                const center = this.calculateOffsetCenter({ lat, lng });
                this.map.setView(center, zoom);
            });

        this.mapService.highlight
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ lat, lng, radius }) => this.highlight({
                lat,
                lng
            }, radius));
    }

    // ----------[ Template Methods ]----------

    protected initializeMap(map: LeafletMap): void {
        this.map = map;
        this.map.addLayer(this.markerClusterGroup);
        this.map.on('moveend', () => {
            const center = this.calculateOffsetCenter(this.map.getCenter());
            this.mapService.centerChanged.emit(center);
            this.mapService.boundsChanged.emit(this.map.getBounds());
        });
    }

    // ----------[ Methods ]----------

    protected createPopupContent(config: { component: any, inputs: any }): HTMLElement {
        const popupDiv = document.createElement('div');

        const componentRef = createComponent(DynamicDialogContentComponent, {
            environmentInjector: this.applicationRef.injector,
            elementInjector: this.injector
        });

        componentRef.setInput('component', config.component);
        componentRef.setInput('inputs', config.inputs);

        this.applicationRef.attachView(componentRef.hostView);

        popupDiv.appendChild(componentRef.location.nativeElement);

        return popupDiv;
    }

    private scrollTo(coordinates: { lat: number, lng: number, xOffset?: number }): void {
        const center = this.calculateOffsetCenter(coordinates);

        this.map.setView(center, this.map.getZoom());
    }

    private addMarker(marker: MapMarker): void {
        if (marker.popup) {
            marker._marker.on('mouseover', () => {
                const popupContent = this.createPopupContent(marker.popup!);
                const popup = marker._marker.bindPopup(popupContent) as Popup
                popup.openPopup();
            });

            // TODO: This is to close the popup when the mouse leaves the marker
            // Maybe put all the close emitters in the popup itself (marker._marker.on)
            marker._marker.on('mouseout', () => {
                marker._marker.closePopup();
            });

            marker.popup.inputs.close = () => {
                marker._marker.closePopup();
            }
        }
        this.markerClusterGroup.addLayer(marker._marker);
    }

    private clearMarkers(): void {
        this.markerClusterGroup.clearLayers();
        if (this.circle) {
            this.map.removeLayer(this.circle);
        }
    }

    private highlight(coordinates: { lat: number, lng: number }, radiusKm: number): void {
        if (this.circle) {
            this.map.removeLayer(this.circle);
        }

        const radiusMeters = radiusKm * 1000;
        const _circle = circle(coordinates, {
            radius: radiusMeters,
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.3
        });
        this.map.addLayer(_circle);
        this.circle = _circle;
    }

    private calculateOffsetCenter(coordinates: { lat: number; lng: number, xOffset?: number }): { lat: number; lng: number } {
        const pixelPoint = this.map.project(coordinates, this.map.getZoom());
        pixelPoint.x -= coordinates.xOffset || this.config().xOffset;
        return this.map.unproject(pixelPoint, this.map.getZoom());
    }

    protected zoomIn(): void {
        this.map.zoomIn();
    }

    protected zoomOut(): void {
        this.map.zoomOut();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

import { Component } from '@angular/core';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { NgClass } from '@angular/common';
import { CompanyOnboardingService, OnboardingStep } from '../../company-onboarding.service';
import { BasicInformationComponent } from '../basic-information/basic-information.component';
import {
    DynamicDialogContentComponent
} from '../../../../core/components/application/dynamic-component/dynamic-dialog-content.component';
import { ContactInformationComponent } from '../contact-information/contact-information.component';
import { LocationComponent } from '../location/location.component';
import { MediaComponent } from '../media/media.component';
import { UserComponent } from '../user/user.component';

@Component({
    selector: 'app-step-wrapper',
    standalone: true,
    imports: [
        AutoAnimateDirective,
        StepperModule,
        ButtonModule,
        NgClass,
        DynamicDialogContentComponent
    ],
    templateUrl: './step-wrapper.component.html',
    styleUrl: './step-wrapper.component.scss'
})
export class StepWrapperComponent {
    currentStep = this.onboardingService.currentStep;
    currentStepIndex = this.onboardingService.currentStepIndex;

    constructor(
        private readonly onboardingService: CompanyOnboardingService
    ) {
    }

    steps = [
        {
            header: 'Bedrijfsinformatie',
            step: OnboardingStep.BasicInformation,
            component: BasicInformationComponent
        },
        {
            header: 'Contactinformatie',
            step: OnboardingStep.ContactInformation,
            component: ContactInformationComponent
        },
        {
            header: 'Locatie',
            step: OnboardingStep.Location,
            component: LocationComponent
        },
        {
            header: 'Uitstraling',
            step: OnboardingStep.Media,
            component: MediaComponent
        },
        {
            header: 'Persoonlijk',
            step: OnboardingStep.User,
            component: UserComponent
        }
    ];
}

<nav
    class="rounded-xl shadow-lg pl-8 pr-4 py-4 w-full pointer-events-auto transition-all duration-150"
    [ngClass]="[
        colorMode() === 'light' ? 'bg-white' : 'bg-black',
        widthMode() === 'full' ? 'w-full' : 'max-w-screen-2xl mx-auto',
    ]"
>
    <div class="flex">
        <div class="md:flex md:gap-x-12">
            <div
                [routerLink]="['/']"
                class="cursor-pointer flex items-center justify-center md:justify-start py-4"
            >
                <img
                    [ngSrc]="colorMode() === 'light' ? '/assets/images/logo_horizontal_black.svg' : '/assets/images/logo_horizontal_white.svg'"
                    priority
                    height="16"
                    width="200"
                    alt="Planmeister logo"
                >
            </div>
            <div
                class="rounded-4xl transition-all duration-150 hidden sm:flex"
                [ngClass]="colorMode() === 'light' ? 'bg-gray-50' : 'bg-gray-800'"
            >
                <app-search-nav-button
                    [colorMode]="colorMode()"
                    [active]="hasMode('demand')"
                    [icon]="faRightLeftLarge"
                    [routerLink]="['/', 'vraag']"
                >
                    <div
                        [ngClass]="{'hidden': hasMode('supply') }"
                        class="xl:block" text i18n="@@component.search-nav.button.demand">
                        Vraag
                    </div>
                </app-search-nav-button>

                <app-search-nav-button
                    [colorMode]="colorMode()"
                    [active]="hasMode('supply')"
                    [icon]="faBars"
                    [routerLink]="['/', 'aanbod']"
                >
                    <div
                        class="py-2 xl:block" text i18n="@@component.search-nav.button.supply">
                        Aanbod
                    </div>
                    <div
                        body
                        class="flex items-center space-x-4 h-full"
                    >
                        <div class="border-l h-full"></div>
                        <div class="flex items-center space-x-2">
                            <div
                                (click)="categorySelectComponent.focusInput()"
                                class="text-gray-800 font-medium text-sm hidden xl:inline-block"
                            >
                                <ng-container i18n="@@component.search-nav.supply.what">
                                    Wat
                                </ng-container>
                            </div>
                            <nav-search-supply-category-select #categorySelectComponent/>
                            <!--                                [ngClass]="{ 'opacity-30': ! hasSupplyMode(null) && ! categorySelectComponent.active()}"-->
                        </div>
                        <div class="border-l h-full"></div>
                        <div class="flex items-center space-x-2" (click)="onDateSectionClick()">
                            <div class="text-gray-800 font-medium text-sm hidden xl:inline-block">
                                <ng-container i18n="@@component.search-nav.supply.when">
                                    Wanneer
                                </ng-container>
                            </div>
                            <nav-search-supply-date-select
                                #dateSelect/>
                            <!-- [ngClass]="{ 'opacity-30': ! hasSupplyMode(null) && ! categorySelectComponent.active()}"-->
                        </div>

                        <div class="border-l h-full"></div>
                        <div class="flex items-center space-x-2">
                            <div
                                (click)="onLocationSelectionClick()"
                                class="text-gray-800 font-medium text-sm hidden xl:inline-block"

                            >
                                <ng-container i18n="@@component.search-nav.supply.where">
                                    Waar
                                </ng-container>
                            </div>
                            <nav-search-supply-location
                                placeholder="Nederland"
                                #locationSelect
                            />
                            <!-- [ngClass]="{ 'opacity-30': ! hasSupplyMode(null) && ! categorySelectComponent.active()}"-->

                        </div>

                    </div>
                </app-search-nav-button>

                <app-search-nav-button
                    [colorMode]="colorMode()"
                    [active]="hasMode('companies')"
                    [icon]="faBuildingMagnifyingGlass"
                    [routerLink]="['/', 'bedrijven']"
                >
                    <div
                        [ngClass]="{'hidden': hasMode('supply') }"
                        class="xl:block" text i18n="@@component.search-nav.button.supply">
                        Bedrijven
                    </div>
                    <div
                        body
                        class="flex items-center space-x-4 h-full"
                    >
                        <div class="border-l h-full"></div>
                        <div class="text-gray-500 font-medium text-sm">
                            <ng-container i18n="@@component.search-nav.company.search">
                                Zoeken
                            </ng-container>
                        </div>
                        <div class="flex items-center space-x-4">
                            <div>
                                <button
                                    type="button"
                                    class="relative w-full cursor-default text-left text-gray-900 sm:text-sm sm:leading-6"
                                >
                                    <div class="block space-x-4">
                                        <app-search-company/>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </app-search-nav-button>
            </div>
        </div>
        <ng-content/>
        <div class="flex-grow"></div>
        <div class="flex gap-x-6">
            <div class="flex items-center">
                <app-nav-auth [colorMode]="colorMode()"/>
            </div>
            @if (authService.isAuthenticated()) {
                <div class="hidden sm:flex">
                    <button
                        (click)="toggleNavMenuActive()"
                        class="inline-flex rounded-full p-3"
                        [ngClass]="colorMode() === 'light' ? 'bg-gray-100 text-gray-800' : 'bg-gray-800 text-white'"
                    >
                        <ng-icon
                            name="heroSquares2x2"
                            size="1.5em"
                            [ngClass]="colorMode() === 'light' ? 'text-black' : 'text-white'"
                        />
                    </button>
                </div>
            }
            <!-- Mobile view (visible on small screens, hidden on larger screens) -->
            <div class="sm:hidden">
                <button
                    (click)="onDownloadAppClicked()"
                    class="inline-flex rounded-full p-3 bg-red-500"
                >
                    <ng-icon
                        name="heroDevicePhoneMobile"
                        size="1.5em"
                        class="text-white"
                        [ngClass]="colorMode() === 'light' ? 'text-black' : 'text-white'"
                    />

                </button>
            </div>
        </div>
    </div>
</nav>

@if (navMenuActive()) {
    <div
        class="py-2 flex justify-end"
        [ngClass]="widthMode() === 'full' ? 'w-full' : 'max-w-screen-2xl mx-auto'">
        <app-nav-menu
            (close)="toggleNavMenuActive()"
            [colorMode]="colorMode()"
        />
    </div>
}

<div class="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
    @switch (currentStep()) {
        @case (OnboardingStep.NotStarted) {
            <onboarding-company-intro/>
        }
        @default {
            <app-step-wrapper />
        }
    }
</div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'app-register-form',
  standalone: true,
    imports: [
        ReactiveFormsModule
    ],
  templateUrl: './register-form.component.html',
})
export class RegisterFormComponent implements OnInit {

    protected readonly form = new FormGroup({
        email: new FormControl(''),
        password: new FormControl(''),
    });

    ngOnInit() {
        this.form.valueChanges.subscribe((value) => {
            // TODO
        });
    }

}

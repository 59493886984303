import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export interface PhoneNumberValidatorOptions {
    regionCode?: string;
    formatOnValid?: boolean;
    format?: PhoneNumberFormat;
}

export function phoneNumberValidator(options: PhoneNumberValidatorOptions = {}): ValidatorFn {
    const {
        regionCode = 'NL',  // Default to Netherlands
        formatOnValid = false,
        format = PhoneNumberFormat.INTERNATIONAL
    } = options;

    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null; // Allow empty values, let required validator handle this if needed
        }

        try {
            let phoneNumber: PhoneNumber;

            // Handle Dutch numbers starting with 0
            if (regionCode === 'NL' && value.startsWith('0')) {
                phoneNumber = phoneNumberUtil.parse(value, 'NL');
            } else {
                phoneNumber = phoneNumberUtil.parseAndKeepRawInput(value, regionCode);
            }

            if (!phoneNumberUtil.isValidNumber(phoneNumber)) {
                return { invalidPhoneNumber: { value } };
            }

            if (formatOnValid) {
                const formattedNumber = phoneNumberUtil.format(phoneNumber, format);
                if (formattedNumber !== value) {
                    control.setValue(formattedNumber, { emitEvent: false });
                }
            }

            return null;
        } catch (error) {
            console.error('Phone number parsing error:', error);
            return { phoneNumberParseError: { value } };
        }
    };
}

import { Component } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faMessage } from '@fortawesome/pro-light-svg-icons/faMessage';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'home-contact',
    standalone: true,
    imports: [
        FaIconComponent
    ],
    templateUrl: './contact.component.html'
})
export class ContactComponent {

    protected readonly faPhone = faPhone;
    protected readonly faEnvelope = faEnvelope;
    protected readonly faWhatsapp = faWhatsapp;
}

import { Injectable } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';
import { LoginModalComponent } from '../components/login-modal/login-modal.component';

@Injectable({
    providedIn: 'root'
})
export class AuthViewService {

    constructor(
        private readonly modalService: ModalService
    ) {
    }

    public openLoginModal() {
        this.modalService.open({
            component: LoginModalComponent,
            inputs: {},
            options: {
                closeOnBackdropClick: false,
                size: '3xl'
            }
        });
    }
}

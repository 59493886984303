import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { ModalService } from '../../../../core/services/modal.service';

@Component(
    {
        selector: 'app-download-app-modal',
        templateUrl: './download-app-modal.component.html',
        standalone: true
    }
)
 export class DownloadAppModalComponent {
    @Output() closeModal = new EventEmitter<void>();
    constructor(
    ) {
    }

}

<div class="bg-white">
    <div class="mx-auto max-w-screen-2xl px-6 2xl:px-0">
        <div class="overflow-hidden rounded-lg bg-black shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
            <div class="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
                <div class="lg:self-center">
                    <h2 class="text-3xl text-white sm:text-4xl">
                        <span class="block font-brand">Overtuigd?</span>
                    </h2>
                    <p class="mt-4 text-xl font-semibold leading-6 text-white">Word nu lid!</p>
                    <p class="mt-4 text-lg leading-6 text-white">Start nu met het online aanbieden van jouw vakmensen, machines, materialen en grond- en bouwstoffen. Weet wat, waar en wanneer door wie wordt aangeboden met behulp van de handige kaartweergave.</p>
                    @if(showCta()) {
                        <a (click)="onCtaClick()" class="cursor-pointer mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-black shadow hover:bg-red-50">Maak een account aan</a>
                    } @else {
                        <a (click)="onProfileClick()" class="cursor-pointer mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-black shadow hover:bg-red-50">Naar mijn inventaris</a>
                    }
                </div>
            </div>
            <div class="aspect-h-3 aspect-w-5 md:aspect-h-1 md:aspect-w-1">
                <img class="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="/assets/images/planmeister-demo-search.png" alt="App screenshot">
            </div>
        </div>
    </div>
</div>

import {
    Component,
    ElementRef,
    input,
    model,
    OnInit,
    output,
    signal,
    ViewChild
} from '@angular/core';
import { LocationInput } from '../../../../../../../graphql/generated';
import { AddressLookupService } from '../../../../services/address-lookup.service';
import { toObservable } from '@angular/core/rxjs-interop';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    Observable,
    of,
    switchMap,
    tap
} from 'rxjs';
import { AutocompleteOffDirective } from '../../../../directives/autocomplete-off.directive';
import { FormsModule } from '@angular/forms';
import { NgIcon } from '@ng-icons/core';
import { SearchDropdownComponent } from '../../../inputs/search-dropdown/search-dropdown.component';
import { MapService } from '../../../../../search/services/map.service';
import { ClickOutsideDirective } from '../../../../directives/click-outside.directive';

@Component({
    selector: 'nav-search-supply-location',
    standalone: true,
    imports: [
        AutocompleteOffDirective,
        FormsModule,
        NgIcon,
        SearchDropdownComponent,
        ClickOutsideDirective
    ],
    templateUrl: './nav-location-search.component.html'
})
export class NavLocationSearchComponent implements OnInit {

    public readonly initial = input<string | null>();

    id = input<string>('address-search-dropdown');
    placeholder = input<string>('Zoek een adres of plaatsnaam');

    search = model<string | null>(null);
    selected = output<LocationInput | null>();

    @ViewChild('locationSearchInput') locationSearchInput!: ElementRef<HTMLInputElement>;

    protected readonly selectedAddressSuggestion = signal<LocationInput | null>(null);
    protected readonly shouldSearch = signal<boolean>(true);
    protected readonly error = signal<string | null>(null);
    protected readonly addressSuggestions = signal<LocationInput[]>([]);
    protected selectedIndex = signal<number>(-1);
    private readonly selectionJustMade = signal<boolean>(false);
    private lastSelectedValue: string | null = null;

    constructor(
        private readonly addressLookupService: AddressLookupService,
        private readonly mapService: MapService
    ) {
        this.initializeAddressSuggestionsSignal();
    }

    public ngOnInit(): void {
        const initial = this.initial();
        if (initial) {
            this.search.set(initial);
            this.shouldSearch.set(false);
            setTimeout(() => this.shouldSearch.set(true), 1000); // DIRTY HACK
        }
    }

    clearSearch() {
        this.search.set(null);
        this.selectedAddressSuggestion.set(null);
        this.addressSuggestions.set([]);
        this.selectedIndex.set(-1);
    }

    protected mapOptionLabel = (option: LocationInput) => option.properties.formattedAddress;

    protected onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case 'ArrowDown':
                this.moveSelection(1);
                event.preventDefault();
                break;
            case 'ArrowUp':
                this.moveSelection(-1);
                event.preventDefault();
                break;
            case 'Enter':
                this.selectCurrentSuggestion();
                event.preventDefault();
                break;
        }
    }

    protected selectAddressSuggestion(suggestion: LocationInput) {

        this.mapService.zoomTo.emit({
            lat: suggestion.geometry.coordinates[1],
            lng: suggestion.geometry.coordinates[0],
            zoom: 12
        });

        this.selected.emit(suggestion);
        const formattedAddress = suggestion.properties.formattedAddress;
        this.search.set(formattedAddress);
        this.lastSelectedValue = formattedAddress;
        this.addressSuggestions.set([]);
        this.selectedAddressSuggestion.set(suggestion);
        this.selectionJustMade.set(true);
        this.selectedIndex.set(-1);
    }

    private initializeAddressSuggestionsSignal(): void {
        const search$ = toObservable(this.search);
        const shouldSearch$ = toObservable(this.shouldSearch);

        search$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(searchTerm => {
                if (searchTerm !== this.lastSelectedValue) {
                    this.selectionJustMade.set(false);
                }
            }),
            filter(() => this.shouldSearch() && !this.selectionJustMade()),
            switchMap(searchTerm => {
                if (!searchTerm) {
                    return of([]);
                }
                return this.performSearch(searchTerm);
            })
        ).subscribe(hits => {
            this.addressSuggestions.set(hits as LocationInput[]);
        });

        // Reset suggestions when shouldSearch changes to false
        shouldSearch$.pipe(
            filter(shouldSearch => !shouldSearch)
        ).subscribe(() => {
            this.addressSuggestions.set([]);
        });
    }

    private performSearch(searchTerm: string): Observable<LocationInput[]> {
        return this.addressLookupService.lookupAddress(searchTerm).pipe(
            tap(() => this.error.set(null)),
            catchError(err => {
                console.error('Error looking up address:', err);
                this.error.set('Er is een fout opgetreden bij het zoeken naar adressen.');
                return of([]);
            })
        );
    }

    private moveSelection(step: number): void {
        const newIndex = this.selectedIndex() + step;
        if (newIndex >= -1 && newIndex < this.addressSuggestions().length) {
            this.selectedIndex.set(newIndex);
        }
    }

    private selectCurrentSuggestion(): void {
        const currentIndex = this.selectedIndex();
        if (currentIndex !== -1) {
            const selectedSuggestion = this.addressSuggestions()[currentIndex];
            this.selectAddressSuggestion(selectedSuggestion);
        }
    }

    closeDropdown() {
        this.addressSuggestions.set([]);
        this.selectedIndex.set(-1);
    }
}

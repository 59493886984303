'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
var prefix = 'fal';
var iconName = 'handshake';
var width = 640;
var height = 512;
var aliases = [];
var unicode = 'f2b5';
var svgPathData = 'M263.9 64c-33.9 0-66.7 12-92.6 33.7L144 120.7l0 39.1c2.7-.5 5.3-1.7 7.4-3.5l40.5-34c13.3-11.2 28.9-19 45.5-23.1L272.6 64l-8.7 0zM112 352.5c8.1 1.3 15.7 5 21.6 10.8l46.1 44.6 24.3 24.3c25.1 25.1 65.4 26.2 91.8 2.6c.8-.7 1.6-1.4 2.3-2.2c26.3 21.3 65.3 18.6 88.4-7.3c6-6.7 10.3-14.4 13-22.5c19.2 4 40-.8 55.7-14.8c11.3-10.1 18.2-23.2 20.6-37l52.2 0 0 17.2c0 26.5 21.5 48 48 48l16 0c26.5 0 48-21.5 48-48l0-208c0-17.7-14.3-32-32-32l-48 0-32 0 0 32 0 158.8-54.9 0c-3.1-8.7-8.1-17-15.1-24l-85.6-85.6 8.1-7.5c6.5-6 6.9-16.1 .9-22.6s-16.1-6.9-22.6-.9l-76.1 70.2c-13.2 12.2-33.6 12.4-47 .4c-15.4-13.7-15.7-37.5-.8-51.6l79.4-75C332.2 105.4 355.8 96 380.4 96c22.7 0 44.6 8 61.9 22.6l44.8 37.7c2.5 2.1 5.6 3.4 8.8 3.7l0-38.1L462.9 94.1C439.8 74.6 410.6 64 380.4 64c-32.8 0-64.3 12.5-88.1 35l-79.4 75c-28.6 27-27.9 72.7 1.5 98.8c25.8 22.9 64.7 22.5 90-.8l44.5-41 86.5 86.5c13 13 12.4 34.3-1.4 46.5c-10.9 9.7-26.7 10.6-38.6 3c-5-3.3-11.4-3.4-16.6-.5s-8.3 8.6-8 14.6c.4 8.1-2.3 16.3-8.1 22.9c-12.3 13.9-33.8 14.5-46.9 1.4l-8.1-8.1c-3.6-3.6-8.7-5.3-13.7-4.5s-9.4 3.9-11.8 8.4c-1.9 3.6-4.4 6.9-7.6 9.8c-13.8 12.3-34.8 11.7-47.8-1.3l-39.9-39.8-.3 .3-30.7-29.7c-11.9-11.5-27.4-18.5-43.8-20L112 160l0-32-32 0-48 0c-17.7 0-32 14.3-32 32L0 368c0 26.5 21.5 48 48 48l16 0c26.5 0 48-21.5 48-48l0-15.5zM80 160l0 208c0 8.8-7.2 16-16 16l-16 0c-8.8 0-16-7.2-16-16l0-208 48 0zm528 0l0 208c0 8.8-7.2 16-16 16l-16 0c-8.8 0-16-7.2-16-16l0-208 48 0z';
exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};
exports.faHandshake = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
<div class="bg-white text-left rounded-lg border-0 text-gray-900 shadow-lg">
    <div class="px-8 py-6 sm:px-6">
        <p-stepper [linear]="true" [activeStep]="currentStepIndex()">
            @for (step of steps; track step.step) {
                <p-stepperPanel [header]="step.header">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
                        <app-dynamic-component [component]="step.component" [inputs]="{
                                prevCallback: prevCallback,
                                nextCallback: nextCallback,
                            }"/>
                    </ng-template>
                </p-stepperPanel>
            }
        </p-stepper>
    </div>
</div>


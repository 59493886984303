import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ScrollBehaviorService {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private viewportScroller: ViewportScroller
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            let route = this.activatedRoute;
            while (route.firstChild) {
                route = route.firstChild;
            }
            route.data.subscribe(data => {
                if (data && data['scrollToTop']) {
                    this.viewportScroller.scrollToPosition([0, 0]);
                }
            });
        });
    }

    public scrollToTop() {
        this.viewportScroller.scrollToPosition([0, 0]);
    }
}

import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home/home-page.component';
import { RegisterPageComponent } from './register/register-page.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MapComponent } from './core/components/map/map.component';
import { ContactPageComponent } from './pages/contact/contact-page.component';
import { EventsPageComponent } from './pages/events/events-page.component';
import { SearchPageComponent } from './search/search-page.component';
import {
    TermsAndConditionsComponent
} from './pages/terms-and-conditions/terms-and-conditions.component';

export const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
        data: {
            animation: 'HomePage',
            scrollToTop: true
        },
    },
    {
        path: 'contact',
        component: ContactPageComponent,
        data: { scrollToTop: true }
    },
    {
        path: 'netwerkavonden',
        component: EventsPageComponent,
        data: { scrollToTop: true }
    },
    {
        path: 'algemene-voorwaarden',
        component:TermsAndConditionsComponent,
        data: { scrollToTop: true }
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        data: { scrollToTop: true }
    },
    {
        path: 'vraag',
        component: SearchPageComponent,
        loadChildren: () => import('./search/demand/demand.module').then(m => m.DemandModule)
    },
    {
        path: 'demand',
        redirectTo: 'vraag',
        pathMatch: 'prefix'
    },
    {
        path: 'aanbod',
        component: SearchPageComponent,
        loadChildren: () => import('./search/supply/supply.module').then(m => m.SupplyModule)
    },
    {
        path: 'supply',
        redirectTo: 'aanbod',
    },
    {
        path: 'search',
        redirectTo: 'aanbod',
        pathMatch: 'prefix'
    },
    {
        path: 'bedrijven',
        component: SearchPageComponent,
        loadChildren: () => import('./search/companies/companies.module').then(m => m.CompaniesModule)
    },
    {
        path: '',
        outlet: 'mapOutlet',
        component: MapComponent
    },
    {
        path: 'register',
        component: RegisterPageComponent,
        data: {
            showHeader: false,
            showMap: false
        }
    },
    {
        path: 'bedrijf',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

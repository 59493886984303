<div class="overflow-hidden">
    <div class="mx-auto mt-24 max-w-screen-2xl px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20">
        <div class="flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-20">
            <div class="w-full lg:w-1/2 max-w-2xl">
                <h2 class="text-2xl sm:text-3xl lg:text-4xl font-bold tracking-tight text-black font-brand">Neem contact met ons op</h2>
                <p class="mt-4 sm:mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-600">Heb je vragen over onze online tool, netwerkavonden of andere zaken? Wij staan je graag persoonlijk te woord en bieden uitgebreide ondersteuning. Ons backoffice-team staat klaar om al je vragen zorgvuldig te beantwoorden en je verder te helpen met het platform.</p>
                <div class="mt-4 text-lg  text-gray-600 font-semibold">
                    <span>Bel gerust en we staan je graag persoonlijk te woord!</span>
                </div>

            </div>
            <div class="w-full lg:w-1/2 max-w-2xl">
                <div class="relative">
                    <img src="/assets/images/homepage/website-11.jpg" alt="" class="w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" style="aspect-ratio: 3/2;">
                    <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
                </div>
            </div>
        </div>

        <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
            <div class="flex gap-x-4 rounded-xl bg-white p-6 shadow-md ring-1 ring-gray-100">
                <svg class="h-7 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clip-rule="evenodd"/>
                </svg>
                <div class="text-base leading-7">
                    <h3 class="font-semibold text-black">Backoffice</h3>
                    <p class="mt-2 text-gray-600 space-y-2">
                        <a href="mailto:info@planmeister.com" class="flex items-center">
                            <fa-icon [icon]="faEnvelope" class="mr-2 text-red-500"></fa-icon>
                            <span class="hover:text-red-500 hover:underline">info&#64;planmeister.com</span>
                        </a>
                        <a href="tel:+31850044938" class="flex items-center">
                            <fa-icon [icon]="faPhone" class="mr-2 text-red-500"></fa-icon>
                            <span class="hover:text-red-500 hover:underline">+31 85 004 49 38</span>
                        </a>
                        <a href="https://wa.me/31621312322" class="flex items-center">
                            <fa-icon [icon]="faWhatsapp" class="mr-2 text-red-500"></fa-icon>
                            <span class="hover:text-red-500 hover:underline">+31 6 213 12 322</span>
                        </a>
                    </p>
                </div>
            </div>
            <div class="flex gap-x-4 rounded-xl bg-white p-6 shadow-md ring-1 ring-gray-100">
                <svg class="h-7 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clip-rule="evenodd"/>
                </svg>
                <div class="text-base leading-7">
                    <h3 class="font-semibold text-black">Bauke Haarsma (Regio Noord-Nederland)</h3>
                    <p class="mt-2 text-gray-600 space-y-2">
                        <a href="mailto:bauke@planmeister.com" class="flex items-center">
                            <fa-icon [icon]="faEnvelope" class="mr-2 text-red-500"></fa-icon>
                            <span class="hover:text-red-500 hover:underline">bauke&#64;planmeister.com</span>
                        </a>
                        <a href="tel:+31850044938" class="flex items-center">
                            <fa-icon [icon]="faPhone" class="mr-2 text-red-500"></fa-icon>
                            <span class="hover:text-red-500 hover:underline">+31 6 218 24 588</span>
                        </a>

                    </p>
                </div>
            </div>
            <div class="flex gap-x-4 rounded-xl bg-white p-6 shadow-md ring-1 ring-gray-100">
                <svg class="h-7 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clip-rule="evenodd"/>
                </svg>
                <div class="text-base leading-7">
                    <h3 class="font-semibold text-black">Roy van de Kant (Regio Zuid-Nederland) </h3>
                    <p class="mt-2 text-gray-600 space-y-2">
                        <a href="mailto:roy@planmeister.com" class="flex items-center">
                            <fa-icon [icon]="faEnvelope" class="mr-2 text-red-500"></fa-icon>
                            <span class="hover:text-red-500 hover:underline">roy&#64;planmeister.com</span>
                        </a>
                        <a href="tel:+31615518891" class="flex items-center">
                            <fa-icon [icon]="faPhone" class="mr-2 text-red-500"></fa-icon>
                            <span class="hover:text-red-500 hover:underline">+31 6 155 18 891</span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>

</div>
<shell-footer/>
